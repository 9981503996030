import { FilledIconButton } from 'components/FilledIconButton';
import Button from '@mui/material/Button';
import type { ButtonType, MenuButtonLayout } from './typings';

const iconStyle = {
  outline: 'solid 1px black',
  borderRadius: '100vmax',
  padding: '3px',
  fontSize: 'calc(1.5rem + 6px)',
  marginRight: '0.2em',
};

const IconMenuButton: MenuButtonLayout = ({ icon: Icon, onClick, ...props }) => (
  <FilledIconButton {...props} onClick={onClick}>
    <Icon />
  </FilledIconButton>
);

const TextWithIconMenuButton: MenuButtonLayout = ({ icon: Icon, onClick, description, ...props }) => (
  <Button {...props} variant='text' color='inherit' onClick={onClick}>
    <Icon style={iconStyle} />
    {description}
  </Button>
);

export const menuButtonDict: {
  [key in ButtonType]: MenuButtonLayout;
} = {
  icon: IconMenuButton,
  textWithIcon: TextWithIconMenuButton,
};
