import { Avatar, AvatarProps, Tooltip, styled, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import VoteIcon from '@mui/icons-material/HowToVote';
import NominateIcon from '@mui/icons-material/VolunteerActivism';
import ResultIcon from '@mui/icons-material/Celebration';
import React, { useMemo } from 'react';
import { Period, PeriodDict } from './typings';

type AvatarWithIconProps = {
  isEnabled?: boolean;
} & AvatarProps;

type PeriodAvatarProps = {
  startDate?: Date;
  isEnabled?: boolean;
  periodName: Period;
  verticalLabels?: boolean;
};

const iconDict: PeriodDict<typeof VoteIcon> = {
  result: ResultIcon,
  nominate: NominateIcon,
  vote: VoteIcon,
};

const tooltipProps: (placememnt: TooltipProps['placement']) => Partial<TooltipProps> = placement => ({
  placement,
  arrow: true,
  open: true,
  PopperProps: {
    popperOptions: {
      placement,
      modifiers: [
        {
          name: 'flip',
          enabled: false,
        },
        {
          name: 'preventOverflow',
          enabled: false,
        },
      ],
    },
  },
});

const PeriodTooltip = styled(({ children, className, placement, ...props }: TooltipProps) => (
  <Tooltip {...tooltipProps(placement)} {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
))(({ theme: { palette }, placement }) => ({
  zIndex: 3,
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '1rem',
    fontWeight: 700,
    backgroundColor: palette.background.paperVariant,
    color: palette.text.primary,
    textTransform: 'capitalize',
    borderRadius: 5,
    ...(placement === 'right-start' && {
      translate: '0 -50%',
    }),
    [`& .${tooltipClasses.arrow}`]: {
      color: palette.background.paperVariant,
      ...(placement === 'right-start' && {
        transform: 'translate(0px, 9px) !important',
      }),
    },
  },
}));

const AvatarWithIcon = styled(
  React.forwardRef<HTMLDivElement, AvatarWithIconProps>(({ children, ...props }, ref) => (
    <Avatar ref={ref} {...props}>
      {children}
    </Avatar>
  )),
  {
    shouldForwardProp: prop => prop !== 'isEnabled',
  },
)(({ theme, isEnabled }) => {
  const backgroundColor = isEnabled ? theme.palette.secondary.main : theme.palette.background.paper;
  const color = isEnabled ? theme.palette.secondary.contrastText : theme.palette.text.primary;
  return {
    backgroundColor,
    color,
    width: '52px',
    height: '52px',
    fontSize: '32px',
    boxShadow: theme.shadows[3],
  };
});

export const PeriodAvatar = ({ startDate, isEnabled = false, periodName, verticalLabels }: PeriodAvatarProps) => {
  const Icon = useMemo(() => iconDict[periodName], [periodName]);
  const parsedDate = useMemo(
    () =>
      startDate?.toLocaleDateString('pl-PL', {
        month: '2-digit',
        day: '2-digit',
      }),
    [startDate],
  );

  const mobilePosition = {
    display: 'block',
    width: 'max-content',
    bottom: -5,
    left: 0,
    right: 0,
    margin: '0 auto',
  };
  const desktopPosition = {
    bottom: 10,
    left: 'calc(100% + 7px)',
  };

  return (
    <div style={{ padding: '17px 0', margin: '6px 0', position: 'relative', zIndex: 2 }}>
      <PeriodTooltip placement={verticalLabels ? 'top' : 'right-start'} title={periodName}>
        <AvatarWithIcon isEnabled={isEnabled}>
          <Icon />
        </AvatarWithIcon>
      </PeriodTooltip>
      <Typography
        variant='titleSmall'
        sx={{ position: 'absolute', ...(verticalLabels ? mobilePosition : desktopPosition) }}>
        {parsedDate}
      </Typography>
    </div>
  );
};
