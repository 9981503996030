import { ThemeProvider } from '@mui/material';
import { useA4BEETheme } from 'theme/theme';
import { useDarkMode } from './context';

const A4BEEThemeProvider = ({ children }: { children?: React.ReactNode }) => {
  const { darkMode } = useDarkMode();
  const { darkTheme, lightTheme } = useA4BEETheme();
  return <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>{children}</ThemeProvider>;
};

export default A4BEEThemeProvider;
