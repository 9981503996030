import { useCallback, useState } from 'react';
import { ConfirmationModalContext } from './context';
import { ConfirmationModal } from './modal';
import type { ConfirmationOptions, ResolveReject } from './typings';

// used so we can not define a handler for cancelling the modal
class PromiseWithDefaultHandler<T> extends Promise<T> {
  catch<TResult = never>(
    onrejected?: ((reason: unknown) => TResult | PromiseLike<TResult>) | null | undefined,
  ): PromiseWithDefaultHandler<T | TResult> {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const onRejectedFun = onrejected ?? (() => {});
    return super.catch(onRejectedFun as () => TResult);
  }
}

export const ConfirmationModalProvider = ({ children }: { children?: React.ReactNode }) => {
  const [options, setOptions] = useState<ConfirmationOptions>();
  const [resolveReject, setResolveReject] = useState<ResolveReject | []>([]);

  const confirm = useCallback((opt?: ConfirmationOptions) => {
    return new PromiseWithDefaultHandler<void>((resolve, reject) => {
      setOptions(opt);
      setResolveReject([resolve, reject]);
    });
  }, []);

  const [resolve, reject] = resolveReject;

  const handleClose = () => {
    options?.onClose?.();
    setResolveReject([]);
    setOptions(undefined);
  };

  const handleConfirm = () => {
    if (!resolve) return;
    resolve();
    options?.onConfirmClick?.();
    handleClose();
  };

  const handleCancel = () => {
    if (!reject) return;
    reject();
    handleClose();
  };

  return (
    <>
      <ConfirmationModalContext.Provider value={confirm}>{children}</ConfirmationModalContext.Provider>
      <ConfirmationModal open={resolveReject.length > 0} onCancel={handleCancel} onConfirm={handleConfirm}>
        {options?.content}
      </ConfirmationModal>
    </>
  );
};
