import React, { useState, useMemo } from 'react';
import { useMediaQuery } from '@mui/material';
import { DarkModeContext } from './context';

export const DarkModeProvider = ({ children }: { children?: React.ReactNode }) => {
  const [darkMode, setDarkMode] = useState(
    window.localStorage.getItem('preferred_mode') === 'dark' ?? useMediaQuery('(prefers-color-scheme: dark)'),
  );

  const toggleDarkMode = () => {
    setDarkMode(prevMode => {
      window.localStorage.setItem('preferred_mode', prevMode ? 'light' : 'dark');
      return !prevMode;
    });
  };
  const value = useMemo(() => ({ darkMode, toggleDarkMode }), [darkMode, toggleDarkMode]);

  return <DarkModeContext.Provider value={value}>{children}</DarkModeContext.Provider>;
};
