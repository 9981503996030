import Container, { containerClasses } from '@mui/material/Container';
import { ReactComponent as Logo } from 'assets/a4bee_hexes.svg';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { FilledIconButton } from '../FilledIconButton';
import { withCutCorner } from './cutCorner';

const FooterContainer = withCutCorner(
  styled('footer')(({ theme }) => ({
    backgroundColor: 'black',
    [`& .${containerClasses.root}`]: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        textAlign: 'center',
        justifyContent: 'space-between',
        '& br': {
          display: 'none',
        },
      },
      ...theme.typography.labelSmall,
      color: theme.palette.neutralVariant[10],
      height: '120px',
      [theme.breakpoints.down(theme.breakpoints.values.lg + 60)]: {
        paddingLeft: '60px !important',
        marginLeft: 0,
      },
    },
  })),
);

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const Footer = () => (
  <FooterContainer>
    <Container>
      <Logo width='50px' fill='#3C4545' />
      <Typography variant='labelSmall' sx={{ ml: { xs: 1, md: 0 } }}>
        © 2023 A4BEE. <br /> ALL RIGHTS RESERVED.
      </Typography>
      <FilledIconButton sx={{ display: { xs: 'flex', md: 'none' }, ml: 'auto' }} onClick={scrollToTop} color='inherit'>
        <ArrowUpwardIcon />
      </FilledIconButton>
    </Container>
  </FooterContainer>
);
