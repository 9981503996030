import { Client } from '../client';
import { User } from './typings';

class UserController {
  static client = Client;

  static getUser(email: string): Promise<User> {
    return this.client.get(`/users/${email}`);
  }

  static getUsers(): Promise<User[]> {
    return this.client.get('/users');
  }
}

export { UserController };
