import { createContext, useMemo, useState } from 'react';
import { useExistingContext } from 'providers/useExistingContext';
import type { EditableSettings } from 'api/controllers/typings';
import { useGetSettings } from 'api/hooks/settings';
import useMediaQuery from '@mui/material/useMediaQuery';
import { type Theme } from '@mui/material/styles';
import type { SettingsContextProviderProps, SettingsContextType } from './typings';

const SettingsContext = createContext<SettingsContextType>({
  state: {
    localSettings: {},
    isVertical: false,
    cantSaveSettings: false,
  },
  /* eslint-disable @typescript-eslint/no-empty-function */
  actions: {
    setPublicKey: () => {},
    toggleSpecifyWallet: () => {},
  },
  /* eslint-enable @typescript-eslint/no-empty-function */
});

export const SettingsContextProvider: React.FC<SettingsContextProviderProps> = ({ children }) => {
  const isVertical = useMediaQuery((theme: Theme) => theme.breakpoints.down(650));
  const [localSettings, setLocalSettings] = useState<EditableSettings>({
    public_key: undefined,
    no_wallet_specified: false,
  });
  const { settings } = useGetSettings({
    onSuccess: newSettings =>
      setLocalSettings({
        public_key: newSettings.public_key,
        no_wallet_specified: newSettings.no_wallet_specified ?? false,
      }),
  });

  const value = useMemo(
    () => ({
      state: {
        cantSaveSettings:
          localSettings.public_key === settings?.public_key &&
          localSettings.no_wallet_specified === (settings?.no_wallet_specified ?? false),
        isVertical,
        localSettings,
      },
      actions: {
        toggleSpecifyWallet: () =>
          setLocalSettings(oldSettings => ({
            ...oldSettings,
            no_wallet_specified: !oldSettings.no_wallet_specified,
          })),
        setPublicKey: (publicKey: string | undefined): void =>
          setLocalSettings(oldSettings => ({ ...oldSettings, public_key: publicKey })),
      },
    }),
    [localSettings, isVertical],
  );

  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
};

export const useSettingsContext = () => useExistingContext(SettingsContext);
