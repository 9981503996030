import { VoteController } from 'api/controllers/VoteController';
import { useQuery } from 'react-query';
import { VoterStatusResponse } from 'api/controllers/typings';
import { GET_STATUS } from '../queryKeys';
import { BasicQueryOptions } from '../typings';

export const useGetVoterStatus = ({ onSuccess, onError }: BasicQueryOptions<VoterStatusResponse> = {}) => {
  const { data, ...rest } = useQuery({
    queryKey: [GET_STATUS],
    queryFn: () => VoteController.getVoterStatus(),
    onSuccess,
    onError,
  });
  return {
    voterStatus: data,
    ...rest,
  };
};
