import IconButton, { type IconButtonProps, iconButtonClasses } from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import { useMemo } from 'react';

export const FilledIconButton = styled(IconButton)(({ theme, color = 'primary' }) => {
  if (color === 'inherit') {
    return {
      background: 'linear-gradient(currentcolor, currentcolor)', // for proper transition
      transitionProperty: 'background',
      '& svg': {
        fill: 'unset',
      },
      '&:hover, &:focus, &:focus-visible': {
        ...theme.palette.blends(3, 'currentcolor'),
      },
    };
  }
  const finalColor = color === 'default' ? 'primary' : color;
  return {
    [`&.${iconButtonClasses.disabled}`]: {
      backgroundColor: theme.palette[finalColor]?.main,
    },
    backgroundColor: theme.palette[finalColor]?.main,
    color: theme.palette[finalColor]?.contrastText,
    '&:hover': {
      backgroundColor: theme.palette[finalColor]?.dark,
    },
  };
});

export type LoadingIconButtonProps = IconButtonProps & {
  loading?: boolean;
  filled?: boolean;
};

export const LoadingIconButton = ({ loading, disabled, children, filled, ...props }: LoadingIconButtonProps) => {
  const ButtonComponent = useMemo(() => (filled ? FilledIconButton : IconButton), [filled]);
  return (
    <ButtonComponent {...props} disabled={disabled || loading}>
      {loading ? <CircularProgress size='24px' color='inherit' /> : children}
    </ButtonComponent>
  );
};
