import { NominationsResponse } from '../typings';

export const MOCK_NOMINATIONS: NominationsResponse = {
  id: '43254543535',
  nomination_period: {
    start_date: '2017-01-01',
    end_date: '2017-01-01',
    name: 'Nomination',
  },
  nominations: [
    {
      nominee_email: 'nominee@test.pl',
      comment: 'You are great',
      value: 'VALUE',
      nominator_email: 'voter@test.pl',
      id: '1',
      creation_date_millis: 123123123,
    },
    {
      nominee_email: 'nominee2@test.pl',
      comment: 'You are great',
      value: 'VALUE',
      nominator_email: 'voter@test.pl',
      id: '2',
      creation_date_millis: 123123123,
    },
  ],
  nominated_users: ['nominee@test.pl', 'nominee2@test.pl'],
};
