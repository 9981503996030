import LoadingButton from '@mui/lab/LoadingButton';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useSetSettings } from 'api/hooks/settings';
import type { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { useSettingsContext } from './SettingsContext';

export type SettingsActionsProps = {
  close: () => void;
};

export const SettingsActions: React.FC<SettingsActionsProps> = ({ close }) => {
  const {
    state: { localSettings, cantSaveSettings },
  } = useSettingsContext();
  const { enqueueSnackbar } = useSnackbar();
  const showSuccess = () => {
    enqueueSnackbar('Settings were saved successfully', {
      variant: 'success',
    });
  };

  const showErrorSnackbar = (error: AxiosError) => {
    if (error?.response?.data) {
      enqueueSnackbar(error.response.data, { variant: 'error' });
    } else {
      enqueueSnackbar('Ups... something went wrong', { variant: 'error' });
    }
  };

  const { mutate: updateSettings, isLoading: loading } = useSetSettings({
    onSuccess: showSuccess,
    onError: showErrorSnackbar,
  });

  const saveSettings = () => {
    if (localSettings) {
      updateSettings(localSettings);
    }
  };

  return (
    <DialogActions
      sx={{ display: 'flex', justifyContent: 'flex-start', gap: '20px', padding: '40px', paddingTop: '20px' }}>
      <LoadingButton
        loading={loading}
        type='button'
        variant='contained'
        disabled={cantSaveSettings}
        onClick={saveSettings}
        loadingPosition='start'
        startIcon={<SaveIcon />}>
        {loading ? 'Saving...' : 'Save'}
      </LoadingButton>
      <Button variant='contained' color='primary' onClick={close} startIcon={<CloseIcon />}>
        Close
      </Button>
    </DialogActions>
  );
};
