import { useMediaQuery, DialogActions, Dialog as MuiDialog, Button } from '@mui/material';
import { withClose } from 'components/drawers/withClose';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { ConfirmationModalProps } from './typings';

const Dialog = withClose(MuiDialog);

export const ConfirmationModal = ({ children, open, onConfirm, onCancel }: ConfirmationModalProps) => {
  const isSmall = useMediaQuery('(width <= 425px)');
  return (
    <Dialog
      PaperProps={{ sx: { bgcolor: 'background.default' } }}
      open={open}
      onClose={onCancel}
      maxWidth={isSmall ? undefined : 'md'}
      fullScreen={isSmall}>
      {children}
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          gap: 1,
          px: 7.5,
          pb: 7.5,
          '>:not(:first-of-type)': { m: 0 },
          '@media (width <= 380px)': { flexDirection: 'column', alignItems: 'flex-start' },
        }}>
        <Button onClick={onConfirm} variant='contained' color='primary' startIcon={<DoneIcon />}>
          Yes
        </Button>
        <Button onClick={onCancel} variant='outlined' color='contrast' startIcon={<CloseIcon />}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
