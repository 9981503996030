import { FC } from 'react';
import { Box, Typography, Stack, Skeleton, Container } from '@mui/material';
import { useUserData } from 'providers/user';
import { GradientSection } from 'components/Layout/GradientSection';
import { createNOf } from 'components/helpers/createNOf';
import NomineeRow from './NomineeRow';
import { useNominationsData } from './useNominationData';

interface NomineeTableProps {
  isVote?: boolean;
  title?: string;
}

export const NomineeTable: FC<NomineeTableProps> = ({ isVote = false, title }) => {
  const { users } = useUserData();
  const { nominatedUsers, isLoading } = useNominationsData();

  return (
    <GradientSection>
      <Container sx={{ pt: 11.25 }}>
        {title && (
          <Typography component='h1' variant='headlineLarge' align='center' gutterBottom>
            {title}
          </Typography>
        )}
        <Stack direction='column'>
          {isLoading
            ? createNOf(3, index => (
                <Box m='0.5em' key={index}>
                  <Skeleton
                    key={index}
                    variant='rounded'
                    height='100px'
                    width='100%'
                    animation={isLoading ? 'wave' : false}
                  />
                </Box>
              ))
            : users
                ?.filter(user => nominatedUsers.includes(user.email))
                .map(user => <NomineeRow key={`user-${user.email}-row`} user={user} isVote={isVote} />)}
        </Stack>
      </Container>
    </GradientSection>
  );
};
