import { useMemo } from 'react';

import { useSnackbarMessages } from 'utils/snackbarMessages';
import { useUserData } from 'providers/user';
import { capitalize } from '@mui/material';
import bee from 'assets/PRIDE_BEE_bg.png';
import { getFirstLastName } from 'utils/getInitials';
import type { LayoutProps, WinnerAvatarProps } from './typings';
import { layoutDict } from './avatarLayouts';

export const WinnerTile = ({ value, nftImage, email, type = 'vertical' }: WinnerAvatarProps) => {
  const handleClick = (link: string | undefined) => {
    window.open(link, '_blank');
  };
  const { snackbarMessage } = useSnackbarMessages();
  const { getUserByEmail } = useUserData();
  const winner = getUserByEmail(email);
  const fromEmailName = getFirstLastName(email);
  const firstName = capitalize(winner?.first_name ?? fromEmailName[0] ?? 'awesome');
  const lastName = capitalize(winner?.last_name ?? fromEmailName[1] ?? 'bee');
  const layoutProps: LayoutProps = {
    firstName,
    lastName,
    photoData: winner?.photo_data ?? bee,
    nftButtonAction: () => (nftImage ? handleClick(nftImage) : snackbarMessage('No NFT found for this winner.')),
    tooltipContent: value?.replace('#', '')?.replace('_', ' '),
    image: nftImage ?? undefined,
  };

  const CurrentLayout = useMemo(() => layoutDict[type], [type]);

  return <CurrentLayout {...layoutProps} />;
};
