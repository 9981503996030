import Pretender from 'pretender';

import { mockDataStream } from '../controllers/mock';

export type MockServerInstance = Pretender;

const mock = (apiUrl: string): void => {
  const server = new Pretender();

  mockDataStream(apiUrl, server);
};

export { mock };
