import { useQuery } from 'react-query';
import { User } from 'api/controllers/typings';
import { UserController } from 'api/controllers/UserController';
import { BasicQueryOptions } from '../typings';
import { GET_USERS } from '../queryKeys';

export const useGetUsers = ({ onSuccess, onError }: BasicQueryOptions<User[]> = {}) => {
  const { data, ...rest } = useQuery({
    queryKey: [GET_USERS],
    queryFn: () => UserController.getUsers(),
    staleTime: Infinity,
    onSuccess,
    onError,
  });
  return {
    users: data,
    ...rest,
  };
};
