import { ReactElement, useMemo } from 'react';
import type { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MobileMenu } from '../Menu/MobileMenu';
import { DesktopMenu } from '../Menu/DesktopMenu';

export const Header = (): ReactElement => {
  const isMobileWidth = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const Menu = useMemo(() => (isMobileWidth ? MobileMenu : DesktopMenu), [isMobileWidth]);
  return (
    <header style={{ padding: '0 2rem', paddingBottom: isMobileWidth ? '40px' : undefined }}>
      <Menu />
    </header>
  );
};
