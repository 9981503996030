import { useGetWinners } from 'api/hooks/winners';
import { useState, useCallback, useMemo, useEffect } from 'react';

export const usePaginatedWinnersList = () => {
  const { data, isLoading, fetchMoreWinners, hasMoreWinners, isFetchingNextPage } = useGetWinners();
  const [currentSlide, setCurrentSlide] = useState(0);
  const onNext = useCallback(() => {
    if ((data?.pages?.length ?? 0) < currentSlide + 3) {
      fetchMoreWinners();
    }
    setCurrentSlide(current => current + 1);
  }, [data?.pages?.length, currentSlide]);

  const onPrev = useCallback(() => setCurrentSlide(current => current - 1), []);

  const arrowProps = useMemo(
    () => ({
      next: {
        onClick: onNext,
        loading: isFetchingNextPage,
        disabled: !hasMoreWinners && currentSlide === (data?.pages?.length ?? 0) - 2,
      },
      prev: {
        onClick: onPrev,
        disabled: currentSlide === 0,
      },
      className: 'winner-list__slider--arrow-container',
    }),
    [currentSlide, isFetchingNextPage, onNext, onPrev, hasMoreWinners, data?.pages?.length],
  );

  useEffect(() => {
    if (!isLoading && data?.pages.length === 1) {
      fetchMoreWinners();
    }
  }, [isLoading, data?.pages.length]);

  return {
    currentWinners: data?.pages[currentSlide + 1]?.winners,
    arrowProps,
    loaded: !(isLoading || isFetchingNextPage),
  };
};
