import { styled, type Theme } from '@mui/material';
import { withCutCorner } from './cutCorner';

export const gradientSectionStyles = ({
  theme: {
    palette: { gradients },
  },
}: {
  theme: Theme;
}) => ({
  background: gradients.bottom({ end: '200px' }),
});

export const GradientSection = withCutCorner(styled('div')(gradientSectionStyles));
