import { type Theme, type CSSObject, styled } from '@mui/material/styles';
import { HTMLAttributes } from 'react';
import { withCutCorner } from './cutCorner';

export type SectionBoxProps = {
  muiStyling?: CSSObject | ((theme: Theme) => CSSObject);
  sectionContainerProps?: HTMLAttributes<HTMLDivElement>;
  pad?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const SectionBoxContainer = withCutCorner(
  styled(({ children, ...props }: SectionBoxProps) => <div {...props}>{children}</div>, {
    shouldForwardProp: name => name !== 'muiStyling',
  })(({ theme, muiStyling }) => ({
    background: theme.palette.background.paperVariant,
    position: 'relative',
    padding: '20px 30px',
    '& ~ .dash': {
      width: 32,
      height: 13,
      position: 'absolute',
      top: 24,
      left: 0,
      transform: 'translateX(-50%)',
      backgroundColor: theme.palette.primary.main,
    },
    ...(typeof muiStyling === 'function' ? muiStyling?.(theme) : muiStyling),
  })),
);

export const SectionBox = ({ children, sectionContainerProps, muiStyling, style, pad, ...props }: SectionBoxProps) => {
  return (
    <div style={{ position: 'relative', ...style, paddingLeft: pad ? '16px' : undefined }} {...props}>
      <SectionBoxContainer {...sectionContainerProps} muiStyling={muiStyling} cornerSize='20px' corner='TR'>
        {children}
      </SectionBoxContainer>
      <div className='dash' style={pad ? { left: '16px' } : undefined} />
    </div>
  );
};
