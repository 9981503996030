import { useReducer, useMemo, type Dispatch } from 'react';
import { AllDrawers } from 'components/drawers/AllDrawers';
import type { DrawerAction, DrawersProperties, DrawerProviderProps, DrawersState } from './typings';
import { AllDrawerKeys, DrawersContext } from './context';
import { useExistingContext } from '../useExistingContext';

const defaultState = Object.fromEntries(AllDrawerKeys.map(key => [key, false])) as DrawersState;

const reducer = (state: DrawersState, { drawer, state: newState }: DrawerAction) => ({
  ...state,
  [drawer]: typeof newState === 'function' ? newState(state[drawer]) : newState,
});

const createActions = (dispatch: Dispatch<DrawerAction>) =>
  Object.fromEntries(
    AllDrawerKeys.map(drawer => [
      drawer,
      {
        open: () => dispatch({ drawer, state: true }),
        close: () => dispatch({ drawer, state: false }),
      },
    ]),
  ) as DrawersProperties;

export const DrawerProvider = ({ children }: DrawerProviderProps) => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  const valueActions = useMemo(
    () => ({
      set: dispatch,
      state: createActions(dispatch),
    }),
    [dispatch],
  );

  const value = useMemo(
    () => ({
      state: Object.fromEntries(
        AllDrawerKeys.map(key => [
          key,
          {
            ...valueActions.state[key],
            isOpen: state[key],
          },
        ]),
      ) as DrawersProperties,
      set: valueActions.set,
    }),
    [state, valueActions],
  );

  return (
    <DrawersContext.Provider value={value}>
      {children}
      <AllDrawers />
    </DrawersContext.Provider>
  );
};

export const useDrawerContext = () => useExistingContext(DrawersContext);
