import {
  Box,
  Avatar,
  TextField,
  FormControl,
  Autocomplete,
  FormHelperText,
  autocompleteClasses,
  styled,
  useMediaQuery,
} from '@mui/material';
import { useUserData } from 'providers/user';
import { useController, useFormContext } from 'react-hook-form';

const BorderlessTextField = styled(TextField)(({ theme }) => ({
  border: '0 !important',
  '&::before, &::after': {
    border: '0 !important',
  },
  '& *': {
    border: '0 !important',
  },
  [`& .${autocompleteClasses.popupIndicator}`]: {
    width: '40px',
    height: '40px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '100vmax',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  [`& .${autocompleteClasses.endAdornment}`]: {
    right: 0,
    top: 'unset',
  },
  [`& .${autocompleteClasses.inputRoot}`]: {
    ...theme.typography.headlineMedium,
    fontWeight: 700,
  },
}));

export const PersonSelectWithAvatar = () => {
  const { control } = useFormContext();
  const { users } = useUserData();
  const {
    field: { onChange, value, ...field },
    fieldState: { error },
  } = useController({
    name: 'nominee_email',
    control,
    rules: {
      required: 'Please choose a bee to nominate!',
    },
  });
  const isNotSmall = useMediaQuery('(width > 440px)');

  const autocompleteValue = users?.find(user => user.email === value) ?? null;
  return (
    <Box display='flex' alignItems='center' justifyContent='space-between' width='100%'>
      {isNotSmall && (
        <div style={{ filter: 'drop-shadow(0 0 5px rgba(0, 0, 0, 0.3))' }}>
          <Avatar
            variant='hex'
            src={users?.find(user => user.email === value)?.photo_data}
            sx={{ width: '120px', height: '120px' }}
          />
        </div>
      )}
      <FormControl sx={{ width: '100%' }}>
        <Autocomplete
          {...field}
          value={autocompleteValue}
          onChange={(_, option) => onChange(option?.email)}
          options={users ?? []}
          renderInput={params => (
            <BorderlessTextField {...params} placeholder='Select a person from the list' error={!!error} multiline />
          )}
          getOptionLabel={option => {
            return `${option.first_name}\n${option.last_name}`;
          }}
          autoComplete
          autoHighlight
          fullWidth
        />
        {error && <FormHelperText error>{error.message}</FormHelperText>}
      </FormControl>
    </Box>
  );
};
