import { NominationItem } from 'api/controllers/typings';

interface UserNominations {
  [userEmail: string]: NominationItem[];
}

interface GetUserNominationsOptions {
  mode?: 'unique' | 'all';
}

export const getUserNominations = (
  userNominations: NominationItem[],
  options: GetUserNominationsOptions = {},
): UserNominations => {
  const { mode = 'unique' } = options;

  return userNominations.reduce<UserNominations>((acc, nomination) => {
    const nomineeEmail = nomination?.nominee_email;
    if (!nomineeEmail) {
      return acc;
    }
    const { value } = nomination;

    if (!acc[nomineeEmail]) {
      acc[nomineeEmail] = [];
    }

    if (mode === 'all' || acc[nomineeEmail].every(n => n.value !== value)) {
      acc[nomineeEmail].push(nomination);
    }

    return acc;
  }, {});
};
