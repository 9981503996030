import { StatisticsController } from 'api/controllers/StatisticsController';
import { useQuery } from 'react-query';
import { CurrentVotingStatisticsResponse } from 'api/controllers/typings';
import { BasicQueryOptions } from '../typings';
import { GET_CURRENT_VOTING_STATISTICS } from '../queryKeys';

export const useGetCurrentVotingStatistics = ({
  onSuccess,
  onError,
}: BasicQueryOptions<CurrentVotingStatisticsResponse> = {}) =>
  useQuery({
    queryKey: [GET_CURRENT_VOTING_STATISTICS],
    queryFn: () => StatisticsController.getCurrentVotingStatistics(),
    onSuccess,
    onError,
  });
