import { createContext } from 'react';
import type { DrawerContextType, Drawers, DrawersProperties } from './typings';

export const AllDrawerKeys: Drawers[] = ['settings', 'menu'];

const defaultState = Object.fromEntries(
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  AllDrawerKeys.map(drawer => [drawer, { isOpen: false, open: () => {}, close: () => {} }]),
) as DrawersProperties;

export const defaultValue = {
  state: defaultState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  set: () => {},
};

export const DrawersContext = createContext<DrawerContextType>(defaultValue);

DrawersContext.displayName = 'DrawersContext';
