import { FC, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import HowToVote from '@mui/icons-material/HowToVote';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import { styled } from '@mui/material/styles';
import { PageTitle } from 'components/Layout/PageTitle';
import { useGetCurrentPeriod } from 'api/hooks/status/useGetCurrentPeriod';
import { useGetVoterStatus } from 'api/hooks/status/useGetVoterStatus';
import { NominationsBox } from 'components/Nominations/NominationsBox';
import { WinnerList } from 'components/WinnerList/WinnerList';
import { NFTGallery } from 'components/NFT/NFTGallery';
import { GradientSection } from 'components/Layout/GradientSection';
import { NomineePage } from 'pages/private/NomineePage';

const CapitalisedButton = styled(Button)(({ theme }) => ({
  textTransform: 'capitalize',
  boxShadow: 'none',
  ...theme.typography.labelLarge,
}));

export const HomePage: FC = () => {
  const { currentPeriod } = useGetCurrentPeriod();
  const [periodDescription, setPeriodDescription] = useState<string>('');
  const { voterStatus } = useGetVoterStatus();

  useEffect(() => {
    switch (currentPeriod?.name) {
      case 'Voting resolved':
        setPeriodDescription(`Acknowledgment is a basic human need, and #Tech_hive culture values recognizing others. 
        Check out the #beeOfTheMonth Wall of Fame and congratulate others on their achievements.

        Do not forget that you can nominate someone, just after a month will end.`);
        break;
      case 'Nomination':
        setPeriodDescription(`Role models deserve appreciation, respect, and recognition.
        Decide who - from #tech_hive - represents our values in the most profound way
        Share their success stories so we can learn from them and celebrate together #theA4BEEway.`);
        break;
      case 'Voting':
        {
          let description = `It is time for voting :-)
        Please look at all nominations and choose who should receive the #beeOfTheMonth award`;
          if (voterStatus?.vote_submitted === true) {
            description = 'THANK YOU! Final results will be announced soon.';
          }
          setPeriodDescription(description);
        }
        break;
      default:
        break;
    }
  }, [currentPeriod, voterStatus?.vote_submitted]);

  return (
    <>
      <GradientSection>
        <Container
          sx={{
            pt: 8,
            pb: 6,
            display: { xs: 'block', lg: 'grid' },
            gap: 4,
            gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
          }}>
          <Box>
            <PageTitle description={periodDescription} />
            {!!voterStatus && voterStatus.vote_submitted && (
              <Typography pt={3} component='div' variant='bodyLarge'>
                You have voted for {voterStatus.voted_for} - Thank you!
              </Typography>
            )}
            <Box display='flex' gap={2} pt='30px' pb={{ xs: 7.5, lg: 0 }} typography='labelLarge'>
              <CapitalisedButton
                disabled={currentPeriod?.name !== 'Nomination'}
                variant='contained'
                startIcon={<VolunteerActivismIcon />}
                href='/nominate'>
                Nominate
              </CapitalisedButton>
              <CapitalisedButton
                disabled={voterStatus?.vote_submitted || currentPeriod?.name !== 'Voting'}
                variant='contained'
                startIcon={<HowToVote />}
                href='/vote'>
                Vote
              </CapitalisedButton>
            </Box>
          </Box>
          <NominationsBox />
        </Container>
        {!['Before Nominations', 'Voting resolved'].includes(currentPeriod?.name ?? '') &&
          !!voterStatus &&
          voterStatus?.vote_submitted && <NomineePage />}
      </GradientSection>
      <WinnerList />
      <NFTGallery />
    </>
  );
};
