import { MockServerInstance } from '../configs/mock';
import { MOCK_ME } from './mocks/get_me';
import { MOCK_NOMINATIONS } from './mocks/get_nominations';
import { MOCK_PERIOD } from './mocks/get_period';
import { MOCK_USERS } from './mocks/get_users';
import { MOCK_USERS_ITEM } from './mocks/get_user_item';
import { MOCK_VOTER_STATUS } from './mocks/get_voter_status';
import { MOCK_WINNERS } from './mocks/get_winners';
import { MOCK_WINNER_ITEM } from './mocks/get_winners_item';

const mockDataStream = (apiUrl: string, server: MockServerInstance): void => {
  server.get(`${apiUrl}/users`, () => [200, { 'Content-Type': 'application/json' }, JSON.stringify(MOCK_USERS)], 700);
  server.get(
    `${apiUrl}/users/{email}`,
    () => [200, { 'Content-Type': 'application/json' }, JSON.stringify(MOCK_USERS_ITEM)],
    700,
  );

  server.get(
    `${apiUrl}/nominations`,
    () => [200, { 'Content-Type': 'application/json' }, JSON.stringify(MOCK_NOMINATIONS)],
    700,
  );

  server.get(`${apiUrl}/period`, () => [200, { 'Content-Type': 'application/json' }, JSON.stringify(MOCK_PERIOD)], 700);

  server.get(
    `${apiUrl}/voter/status`,
    () => [200, { 'Content-Type': 'application/json' }, JSON.stringify(MOCK_VOTER_STATUS)],
    700,
  );

  server.get(
    `${apiUrl}/winners`,
    () => [200, { 'Content-Type': 'application/json' }, JSON.stringify(MOCK_WINNERS)],
    700,
  );

  server.get(
    `${apiUrl}/winners/{id}`,
    () => [200, { 'Content-Type': 'application/json' }, JSON.stringify(MOCK_WINNER_ITEM)],
    700,
  );

  server.get(`${apiUrl}/me`, () => [200, { 'Content-Type': 'application/json' }, JSON.stringify(MOCK_ME)], 700);

  server.post(`${apiUrl}/votes`, () => [200, { 'Content-Type': 'application/json' }, ''], 1700);
  server.post(`${apiUrl}/nominations`, () => [200, { 'Content-Type': 'application/json' }, ''], 1700);
};

export { mockDataStream };
