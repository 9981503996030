type TextParams = {
  fontSize: number;
  lineHeight: string;
  fontWeight: number;
};

export type TextTypes = 'display' | 'headline' | 'title' | 'label' | 'body';
export type TextSizes = 'Small' | 'Medium' | 'Large';
export type TextTypesAndSizes = `${TextTypes}${TextSizes}`;

const textTypes: {
  [type in TextTypes]: TextParams;
} = {
  display: {
    lineHeight: '110%',
    fontWeight: 700,
    fontSize: 45,
  },
  headline: {
    lineHeight: '120%',
    fontWeight: 700,
    fontSize: 28,
  },
  title: {
    lineHeight: '120%',
    fontWeight: 700,
    fontSize: 16,
  },
  label: {
    lineHeight: '110%',
    fontWeight: 700,
    fontSize: 12,
  },
  body: {
    lineHeight: '140%',
    fontWeight: 400,
    fontSize: 14,
  },
};

const textSizes: {
  [size in TextSizes]: { fontScale: number };
} = {
  Large: {
    fontScale: 1.26666,
  },
  Medium: {
    fontScale: 1,
  },
  Small: {
    fontScale: 0.8,
  },
};

export const typographyVariants: {
  [name in TextTypesAndSizes]?: React.CSSProperties;
} = Object.fromEntries(
  Object.entries(textTypes).flatMap(([typeName, { fontSize: initial, ...rest }]) =>
    Object.entries(textSizes).map(([sizeName, { fontScale }]): [string, TextParams] => [
      `${typeName}${sizeName}`,
      {
        fontSize: fontScale * initial,
        ...rest,
      },
    ]),
  ),
);
