import {
  FormProvider,
  useForm,
  type UseFormProps,
  type DeepPartial,
  type FieldValues,
  type Path,
} from 'react-hook-form';
import { useEffect, useImperativeHandle, forwardRef, type HTMLAttributes, type ForwardedRef } from 'react';

export type FormContainerProps<T extends FieldValues = FieldValues> = {
  children?: React.ReactNode;
  className?: string;
  formProps?: HTMLAttributes<HTMLFormElement>;
  defaultValues?: DeepPartial<T>;
  loadValues?: Partial<T>;
  onChange?: (data: T) => void;
  onSuccess: (data: T) => void;
  useFormProps?: Partial<UseFormProps<T>>;
};

export type FormContainerRef = {
  reset: () => void;
};

const FormContainer = <T extends FieldValues = FieldValues>(
  {
    children,
    className,
    formProps,
    defaultValues,
    loadValues,
    onChange,
    onSuccess,
    useFormProps,
  }: FormContainerProps<T>,
  ref: ForwardedRef<FormContainerRef>,
) => {
  const methods = useForm<T>({
    ...useFormProps,
    defaultValues: defaultValues ?? useFormProps?.defaultValues,
  });
  const { handleSubmit, watch, setValue, reset } = methods;

  const formWatch = watch();

  useEffect(() => {
    if (loadValues) {
      Object.entries(loadValues).map(([name, value]) => setValue(name as Path<T>, value));
    }
  }, [loadValues]);

  useEffect(() => {
    onChange?.(formWatch);
  }, [formWatch]);

  useImperativeHandle(ref, () => ({
    reset: () => reset(),
  }));

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSuccess)}
        noValidate
        {...formProps}
        className={`${className ?? ''} ${formProps?.className}`}>
        {children}
      </form>
    </FormProvider>
  );
};

export default forwardRef(FormContainer);
