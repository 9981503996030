import { styled } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { ReactNode } from 'react';

const componentName = 'SnackbarItem';
type Variant = 'info' | 'error' | 'warning' | 'success';

const SnackbarThemeProvider = styled(SnackbarProvider)(({ theme: { palette } }) => ({
  ...Object.fromEntries(
    ['Info', 'Error', 'Warning', 'Success'].map(variant => [
      `&.${componentName}-variant${variant}`,
      {
        backgroundColor: `${palette[variant.toLowerCase() as Variant].main} !important`,
        color: `${palette[variant.toLowerCase() as Variant].contrastText} !important`,
      },
    ]),
  ),
}));

export const ThemedSnackbarProvider = ({ children }: { children?: ReactNode }) => (
  <SnackbarThemeProvider maxSnack={3}>{children}</SnackbarThemeProvider>
);
