import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SliderArrows from 'components/Slider/SliderArrows';
import { WinnerTile } from 'components/WinnerTile/WinnerTile';
import { winnerToWinnerTile } from 'components/WinnerTile/toWinnerTileProps';
import { Skeleton } from '@mui/material';
import { createNOf } from 'components/helpers/createNOf';
import { withCutCorner } from '../Layout/cutCorner';

import { usePaginatedWinnersList } from './usePaginatedWinnersList';

const PaginatedWinnersContainer = withCutCorner(
  styled('div')(({ theme: { palette, spacing, typography } }) => ({
    background: palette.background.paperVariant,
    position: 'relative',
    left: 0,
    height: '100%',
    ...typography.titleSmall,
    color: palette.neutralVariant[palette.mode === 'dark' ? 50 : 60],
    padding: spacing(2.5),
    paddingBottom: spacing(2.375),
    '.winner-list__page--container': {
      paddingRight: spacing(2.5),
      paddingTop: spacing(2.5),
    },
    '> div': {
      overflowX: 'auto',
      '&, & > *': {
        transform: 'rotate(180deg)',
      },
    },
  })),
);

export const PaginatedWinnersList = () => {
  const { loaded, currentWinners, arrowProps } = usePaginatedWinnersList();
  return (
    <Box display='flex' flexDirection='column' position='relative'>
      <div className='winner-list__paginated--container'>
        <PaginatedWinnersContainer corner='BL' cornerSize='20px'>
          <div>
            <div className='winner-list__page--container'>
              {loaded
                ? [
                    ...(currentWinners?.map(listItem => (
                      <div key={listItem.voting_id}>
                        <div className='winner-list__page'>
                          {listItem.voting_id.replace('-', ' ')}
                          {listItem.winners.map(winner => (
                            <WinnerTile key={winner.email} {...winnerToWinnerTile(winner)} type='vertical' />
                          ))}
                        </div>
                      </div>
                    )) ?? []),
                    ...createNOf(Math.max(3 - (currentWinners?.length ?? 0), 0), i => (
                      <div key={`${i}_winners_list_filler`} style={{ width: '219px' }} />
                    )),
                  ]
                : createNOf(3, index => (
                    <div key={index} className='winner-list__page'>
                      <Skeleton width='219px' />
                      {createNOf(3, i => (
                        <Skeleton
                          key={`winner_${index}_skeleton_${i}`}
                          variant='rounded'
                          height='52.5px'
                          width='219px'
                        />
                      ))}
                    </div>
                  ))}
            </div>
          </div>
        </PaginatedWinnersContainer>
      </div>
      <SliderArrows {...arrowProps} />
    </Box>
  );
};
