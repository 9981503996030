import { SvgIconProps } from '@mui/material';
import Box from '@mui/material/Box';
import { FC, Fragment } from 'react';
import { NumberWithRectangles } from './NumberWithRectangles';
import { PersistantTooltip, PersistantTooltipProps } from './PersistantTooltip/TopdownTooltip';

export type CounterWithIconProps = {
  number: number | null;
  Icon: React.ComponentType<SvgIconProps> | React.ComponentType<React.SVGProps<SVGSVGElement>>;
  total?: number | null;
  tooltip?: {
    content: string;
  } & Omit<PersistantTooltipProps, 'children'>;
};

export const CounterWithIcon: FC<CounterWithIconProps> = ({ number, Icon, total, tooltip }) => {
  const { content, ...props } = tooltip ?? {};
  return (
    <Box position='relative' display='flex' flexDirection='column' justifyContent='center' alignItems='flex-start'>
      <Box width='min-content' fontSize={{ xs: '120px', sm: '160px' }} display='flex' alignItems='flex-end'>
        <Icon style={{ width: '.635em', height: '.625em', marginBottom: '0.01em' }} />
        <NumberWithRectangles num={number} fontSize='0.8em' />
        {total && (
          <Box
            sx={{
              position: 'absolute',
              top: '0',
              right: '0',
              display: 'flex',
              alignItems: 'center',
              fontSize: '.375em',
              gap: '.2em',
              transform: 'translateX(105%)',
            }}>
            / <NumberWithRectangles num={total} />
          </Box>
        )}
      </Box>
      {tooltip && (
        <PersistantTooltip {...(props as Omit<PersistantTooltipProps, 'children'>)}>
          {content?.split('\n').map((text, idx, arr) => (
            <Fragment key={idx}>
              {text}
              {idx !== arr.length - 1 && <br />}
            </Fragment>
          ))}
        </PersistantTooltip>
      )}
    </Box>
  );
};
