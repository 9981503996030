import { Theme, createTheme } from '@mui/material';
import { useMemo } from 'react';
import { darkThemeOptions, lightThemeOptions } from 'theme/themeOptions';

const applyHStyles = (theme: Theme) => {
  const newTheme = { ...theme };
  newTheme.typography.h2 = {
    fontSize: '2.2rem',
    fontWeight: 300,
    [newTheme.breakpoints.up('lg')]: {
      fontSize: '3.5rem',
      fontWeight: 300,
    },
  };

  newTheme.typography.h5 = {
    fontSize: '1.2rem',
    [newTheme.breakpoints.up('lg')]: {
      fontSize: '1.5rem',
    },
  };
  return newTheme;
};

export const useA4BEETheme = () => {
  const darkTheme = useMemo(() => applyHStyles(createTheme(darkThemeOptions)), []);
  const lightTheme = useMemo(() => applyHStyles(createTheme(lightThemeOptions)), []);
  return {
    darkTheme,
    lightTheme,
  };
};
