import { forwardRef } from 'react';
import type { CSSUnit } from 'components/css/types';

type CornerTypes = `${'T' | 'B'}${'L' | 'R'}`;

export type CutCornerProps = {
  corner?: CornerTypes | CornerTypes[];
  cornerSize?: `${number}${CSSUnit}`;
};

type PassThroughProps = {
  className?: string;
  style?: React.CSSProperties;
};

function useCutCorner({
  corner = 'TL',
  cornerSize = '60px',
  className,
  style,
}: CutCornerProps & PassThroughProps = {}) {
  return {
    className: `${Array.isArray(corner) ? corner.map(single => `corner-${single}`).join(' ') : `corner-${corner}`} ${
      className ?? ''
    }`,
    style: {
      ...style,
      '--corner': cornerSize,
    },
  };
}

function withCutCorner<T extends PassThroughProps = PassThroughProps>(WrapperComponent: React.ComponentType<T>) {
  return forwardRef(({ corner, cornerSize, className, style, ...props }: T & CutCornerProps, ref) => (
    <WrapperComponent
      {...(props as unknown as T)}
      {...useCutCorner({ corner, cornerSize, className, style })}
      ref={ref}
    />
  ));
}

export { withCutCorner, useCutCorner };
