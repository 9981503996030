import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Footer } from 'components/Layout/Footer';
import { Header } from 'components/Layout/Header/Header';

export const MainLayout: FC = () => (
  <>
    <Header />
    <main>
      <Outlet />
    </main>
    <Footer />
  </>
);
