import { Client } from '../client';

import { Token } from './typings';

class TokenController {
  static client = Client;

  static getTokens(): Promise<Token[]> {
    return this.client.get(`/tokens`);
  }
}

export { TokenController };
