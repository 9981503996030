import {
  Box,
  MenuItem,
  Select,
  ListItemText,
  InputAdornment,
  selectClasses,
  inputClasses,
  inputAdornmentClasses,
  styled,
} from '@mui/material';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import NominateIcon from '@mui/icons-material/VolunteerActivism';
import { useController, useFormContext } from 'react-hook-form';
import { useState, useEffect } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

type ValueInputAndSubmitProps = {
  isLoading?: boolean;
};

const values = [
  ['Innovation', 'We look for the impact in whatever we do'],
  ['Passion', 'We need to feel that things we do are worth working on'],
  ['High Quality', 'We do not settle on mediocracy'],
  ['Trust', "We have each other's back"],
  ['Relationship', 'Behind every technology, project or initiative, there are people'],
];
const ValueSelect = styled(Select<string>)(({ theme: { palette, typography } }) => ({
  border: 0,
  ...typography.labelLarge,
  [`&, &.${inputClasses.focused}`]: {
    backgroundColor: palette.primary.main,
  },
  '& *, & *:where(:hover, :focus, :focus-visible)': {
    border: '0 !important',
  },
  '&:where(:hover, :focus, :focus-visible)': {
    border: '0 !important',
  },
  [`&:hover`]: {
    background: palette.primary.dark,
  },
  '&::after': {
    border: '0 !important',
  },
  '&::before': {
    border: '0 !important',
  },
  [`& .${selectClasses.select}`]: {
    minHeight: 'unset !important',
    backgroundColor: 'transparent !important',
  },
  [`&, & .${inputAdornmentClasses.root}, & .${selectClasses.icon}`]: {
    color: palette.primary.contrastText,
  },
}));

const ValueBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  [theme.breakpoints.between(380, 'lg')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'start',
    padding: '0 10px',
  },
  [theme.breakpoints.down(380)]: {
    justifyContent: 'start',
  },
  gap: '10px',
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const ValueInputAndSubmit = ({ isLoading }: ValueInputAndSubmitProps) => {
  const [cantSubmit, setCantSubmit] = useState(true);
  const { control, watch } = useFormContext();
  const {
    field: { value, ...field },
  } = useController({ name: 'value', control, rules: { required: true } });
  const watchForm = watch();
  useEffect(() => {
    setCantSubmit(!!Object.values(watch()).some(val => !val));
  }, [watchForm]);

  return (
    <ValueBox>
      <ValueSelect
        {...field}
        value={value}
        startAdornment={
          value === '' ? (
            <InputAdornment position='start'>
              <Diversity3Icon />
            </InputAdornment>
          ) : undefined
        }
        renderValue={current => (current === '' ? 'Pick a value' : current.slice(1).replace('_', ' '))}
        MenuProps={{
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'bottom',
          },
        }}
        displayEmpty>
        {values?.map(([a4beeValue, description]) => (
          <MenuItem key={a4beeValue} value={`#${a4beeValue.toLocaleUpperCase().replace(' ', '_')}`}>
            <ListItemText primary={a4beeValue.toLocaleUpperCase()} secondary={description} />
          </MenuItem>
        ))}
      </ValueSelect>
      <LoadingButton
        disabled={cantSubmit}
        loading={isLoading}
        sx={{ boxShadow: 0 }}
        type='submit'
        variant='contained'
        color='primary'
        startIcon={<NominateIcon />}>
        Nominate the Bee
      </LoadingButton>
    </ValueBox>
  );
};
