import { styled } from '@mui/material';
import { type HTMLAttributes } from 'react';

type HighlightProps = {
  color?: string;
  text: string;
} & Omit<HTMLAttributes<HTMLSpanElement>, 'children'>;

export const Highlight = styled(({ text, ...props }: HighlightProps) => <span {...props}>{text}</span>, {
  shouldForwardProp: propName => ['color', 'text'].includes(propName as string),
})(({ theme, color }) => ({
  display: 'inline-block',
  backgroundColor: color ?? theme.palette.primary[theme.palette.mode === 'dark' ? 'contrastText' : 'main'],
}));
