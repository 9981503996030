import { DarkModeSwitch } from 'components/DarkModeSwitch';
import Timeline from 'components/Timeline/Timeline';
import { Spin as Hamburger } from 'hamburger-react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useDrawerContext } from 'providers/drawers/provider';
import { useCutCorner } from '../cutCorner';
import { BeeButton } from '../Header/BeeButton';
import { UserGreeting } from '../Header/UserGreeting';

const MenuBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '88px',
  margin: '0 -2rem',
  padding: '0 2rem',
  backgroundColor: theme.palette.background.paper,
  '& .hamburger-react': {
    borderRadius: '100vmax',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

/* eslint-disable react/destructuring-assignment */
export const MobileMenu = () => {
  const {
    state: {
      menu: { isOpen },
    },
    set,
  } = useDrawerContext();
  const toggleOpen = (state: boolean | ((prevState: boolean) => boolean)) => {
    set({ drawer: 'menu', state });
  };
  return (
    <>
      <MenuBox {...useCutCorner({ corner: 'BR', cornerSize: '20px' })}>
        <BeeButton />
        <Hamburger direction='left' size={26} toggle={toggleOpen} toggled={isOpen} />
      </MenuBox>
      <Box pt={5} pb='15px' typography='headlineMedium' display='flex' justifyContent='space-between'>
        <UserGreeting />
        <DarkModeSwitch />
      </Box>
      <Timeline verticalLabels />
    </>
  );
};
/* eslint-enable react/destructuring-assignment */
