import { FC } from 'react';
import { Box, Container, Grid, ListItemIcon, Typography } from '@mui/material';
import { ReactComponent as HighQualityIcon } from 'assets/highquality.svg';
import { ReactComponent as InnovationIcon } from 'assets/innovation.svg';
import { ReactComponent as PassionIcon } from 'assets/passion.svg';
import { ReactComponent as RelationshipIcon } from 'assets/relationship.svg';
import { ReactComponent as TrustIcon } from 'assets/trust.svg';
import { Highlight } from 'components/Layout/Highlight';

const iconStyle = {
  width: '120px',
  height: '120px',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
};

const values = [
  {
    label: 'Innovation',
    description:
      'We look for the impact in whatever we do. We push the boundaries, improving, accelerating, making things smarter and better.',
    icon: <InnovationIcon style={iconStyle} />,
  },
  {
    label: 'Passion',
    description: "It's not enough to know how to do things. We need to feel that they are worth working on.",
    icon: <PassionIcon style={iconStyle} />,
  },
  {
    label: 'Relationship',
    description:
      'Behind every technology, project, or initiative there are people. We see them and we care about them. Always.',
    icon: <RelationshipIcon style={iconStyle} />,
  },
  {
    label: 'High Quality',
    description: 'We do not settle on mediocracy. We always want to be proud of our work.',
    icon: <HighQualityIcon style={iconStyle} />,
  },
  {
    label: 'Trust',
    description: "We have each other's back. We have our customers back too!",
    icon: <TrustIcon style={iconStyle} />,
  },
];

export const ValuesDescription: FC = () => {
  return (
    <Container maxWidth='lg'>
      <Box marginTop={10}>
        <Typography variant='displaySmall' fontWeight='700'>
          &quot;the A4BEE way&quot; - <Highlight text='values driven' /> work:
        </Typography>
      </Box>
      <Box sx={{ mt: 10, display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(155px, 1fr))' }} gap={2}>
        {values.map(value => (
          <Grid key={value.label} container direction='column' alignItems='flex-start' sx={{ padding: '10px' }}>
            <Grid item>
              <ListItemIcon>{value.icon}</ListItemIcon>
            </Grid>
            <Grid item textAlign='left'>
              <Typography component='h5' variant='h5' align='center' color='text.primary' textAlign='left'>
                {value.label}
              </Typography>
              <Typography variant='body2' fontWeight='400'>
                {value.description}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Box>
    </Container>
  );
};
