import { Client } from '../client';
import { Period, PeriodsResponse, VoteData, VoterStatusResponse } from './typings';

class VoteController {
  static client = Client;

  static getPeriods(): Promise<PeriodsResponse> {
    return this.client.get('/periods');
  }

  static getCurrentPeriod(): Promise<Period> {
    return this.client.get('/periods/current');
  }

  static getVoterStatus(): Promise<VoterStatusResponse> {
    return this.client.get('/voter/status');
  }

  static addVote(data: VoteData): Promise<void> {
    return this.client.post('/votes', data);
  }
}

export { VoteController };
