import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button from '@mui/material/Button';
import { useSettingsContext } from './SettingsContext';

export const SetKeyPreferencesRow = () => {
  const {
    state: { isVertical, localSettings },
    actions: { setPublicKey, toggleSpecifyWallet },
  } = useSettingsContext();

  const clearKey = () => setPublicKey('');

  const handlePublicKeyChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setPublicKey(e.currentTarget.value);
  };

  const inputDisabled = localSettings?.no_wallet_specified ?? false;
  const value = inputDisabled ? '' : localSettings?.public_key || '';
  return (
    <Box width='100%'>
      <Typography variant='titleMedium' display='block' pb={2.5}>
        Your digital wallet address (public)
      </Typography>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='stretch'
        gap='30px'
        flexDirection={isVertical ? 'column' : undefined}>
        <OutlinedInput
          id='textfield-public-key'
          placeholder='Public key'
          inputProps={{ maxLength: 44 }}
          value={value}
          disabled={inputDisabled}
          fullWidth
          onChange={handlePublicKeyChange}
          endAdornment={
            value !== '' ? (
              <InputAdornment position='end'>
                <IconButton onClick={clearKey} disabled={inputDisabled} edge='end'>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ) : undefined
          }
        />
        <Typography variant='titleMedium' display='flex' alignItems='center'>
          OR
        </Typography>
        <Button
          variant='contained'
          sx={{ width: '100%' }}
          onClick={toggleSpecifyWallet}
          startIcon={localSettings.no_wallet_specified ? <CheckCircleIcon /> : <UnpublishedIcon />}>
          I {localSettings?.no_wallet_specified ? '' : "don't "}want to create a wallet
        </Button>
      </Box>
    </Box>
  );
};
