import { TokenController } from 'api/controllers/TokenController';
import { useQuery } from 'react-query';
import { Token } from 'api/controllers/typings';
import { BasicQueryOptions } from './typings';
import { GET_TOKENS } from './queryKeys';

export const useGetTokens = ({ onSuccess, onError }: BasicQueryOptions<Token[]> = {}) => {
  const { data, ...rest } = useQuery({
    queryKey: [GET_TOKENS],
    queryFn: () => TokenController.getTokens(),
    onSuccess,
    onError,
  });
  return {
    tokens: data,
    ...rest,
  };
};
