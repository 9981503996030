import Box, { type BoxProps } from '@mui/material/Box';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import SupportIcon from '@mui/icons-material/Support';
import SettingsIcon from '@mui/icons-material/Settings';
import { useSnackbarMessages } from 'utils/snackbarMessages';
import { useAuth } from 'providers/auth';
import { useMemo } from 'react';
import { useDrawerContext } from 'providers/drawers/provider';
import { type ButtonProps } from '@mui/material/Button';
import type { ButtonDescription, ButtonType } from './typings';
import { menuButtonDict } from './menuButtonDict';

export type MenuButtonsProps = {
  type: ButtonType;
  onClickAny?: () => void;
  buttonProps?: Omit<ButtonProps, 'children' | 'onClick' | 'href'>;
} & Omit<BoxProps, 'component' | 'children'>;

export const MenuButtons: React.FC<MenuButtonsProps> = ({ type, onClickAny, buttonProps, ...navProps }) => {
  const {
    actions: { signOut },
  } = useAuth();
  const { comingSoon } = useSnackbarMessages();

  const {
    state: {
      settings: { open },
    },
  } = useDrawerContext();
  const buttons: ButtonDescription[] = [
    {
      icon: HomeIcon,
      action: '/',
      description: 'Home',
    },
    {
      icon: SupportIcon,
      action: comingSoon,
      description: 'Support',
    },
    {
      icon: SettingsIcon,
      action: open,
      description: 'Settings',
    },
    {
      icon: LogoutIcon,
      action: () => signOut(),
      description: 'Logout',
    },
  ];
  const MenuButton = useMemo(() => menuButtonDict[type], [type]);

  return (
    <Box component='nav' {...navProps}>
      {buttons.map(({ action, ...props }, i) =>
        typeof action === 'string' ? (
          <MenuButton key={i} {...buttonProps} {...props} href={action} onClick={onClickAny} />
        ) : (
          <MenuButton
            key={i}
            {...buttonProps}
            {...props}
            onClick={() => {
              action();
              onClickAny?.();
            }}
          />
        ),
      )}
    </Box>
  );
};
