import { PaginationOptions } from 'api/controllers/typings';

const generatePaginationQueryParams = (params: PaginationOptions) => {
  const queryParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      queryParams.append(key, value.toString());
    }
  });
  return queryParams.toString();
};

export { generatePaginationQueryParams };
