import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

export const useSnackbarMessages = () => {
  const { enqueueSnackbar } = useSnackbar();

  const snackbarMessage = useCallback(
    (message: string) => {
      enqueueSnackbar(message, { variant: 'info' });
    },
    [enqueueSnackbar],
  );

  const comingSoon = () => snackbarMessage('Coming soon!');

  return {
    comingSoon,
    snackbarMessage,
  };
};
