import { WinnerController } from 'api/controllers/WinnerController';
import { useInfiniteQuery } from 'react-query';
import { PaginatedWinnersResponse } from 'api/controllers/typings';
import { BasicQueryOptions } from '../typings';
import { GET_WINNERS } from '../queryKeys';

export type UseGetWinners = BasicQueryOptions<PaginatedWinnersResponse>;

export const useGetWinners = ({ onSuccess, onError }: UseGetWinners = {}) => {
  const { fetchNextPage, hasNextPage, ...rest } = useInfiniteQuery(
    [GET_WINNERS],
    ({ pageParam }) =>
      WinnerController.getPaginatedWinners({ exclusiveStartKey: pageParam, limit: pageParam === undefined ? 1 : 3 }),
    {
      onSuccess: data => {
        const lastPage = data.pages.at(-1);
        if (lastPage) {
          onSuccess?.(lastPage);
        }
      },
      onError,
      keepPreviousData: true,
      getNextPageParam: lastPage => lastPage?.last_evaluated_key,
      retry: false,
    },
  );
  return {
    fetchMoreWinners: fetchNextPage,
    hasMoreWinners: hasNextPage,
    ...rest,
  };
};
