import { useAuth } from 'providers/auth';
import Typography, { type TypographyProps } from '@mui/material/Typography';
import { useUserData } from 'providers/user';

export const UserGreeting = ({ variant = 'headlineMedium', ...props }: Omit<TypographyProps, 'children'>) => {
  const {
    state: { user },
  } = useAuth();
  const { getUserByEmail } = useUserData();
  return (
    <Typography variant={variant} {...props}>
      Hello, {getUserByEmail(user?.email ?? '')?.first_name}
    </Typography>
  );
};
