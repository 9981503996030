import { useInfiniteQuery } from 'react-query';
import { NominationsController } from 'api/controllers/NominationController';
import { PaginatedNominationsResponse } from 'api/controllers/typings';
import { GET_NOMINATIONS, NOMINATIONS_RECEIVED } from '../queryKeys';
import { BasicQueryOptions } from '../typings';

export const useGetReceivedNominations = ({
  onSuccess,
  onError,
}: BasicQueryOptions<PaginatedNominationsResponse> = {}) => {
  const { fetchNextPage, hasNextPage, ...rest } = useInfiniteQuery(
    [GET_NOMINATIONS, NOMINATIONS_RECEIVED],
    ({ pageParam }) =>
      NominationsController.getReceivedNominations({
        exclusiveStartKey: pageParam,
        limit: pageParam === undefined ? 5 : 10,
      }),
    {
      onSuccess: data => {
        const lastPage = data.pages.at(-1);
        if (lastPage) {
          onSuccess?.(lastPage);
        }
      },
      onError,
      keepPreviousData: true,
      getNextPageParam: lastPage => lastPage?.last_evaluated_key,
      retry: false,
    },
  );
  return {
    fetchNext: fetchNextPage,
    hasMore: hasNextPage,
    receivedNominations: rest.data,
    ...rest,
  };
};
