import { Token, WinnerItem } from 'api/controllers/typings';
import { WinnerAvatarProps } from './typings';

export const tokenToWinnerTile: (t: Token) => Omit<WinnerAvatarProps, 'type'> = ({ email, value, image }) => ({
  email,
  value,
  nftImage: image,
});

export const winnerToWinnerTile: (w: WinnerItem) => Omit<WinnerAvatarProps, 'type'> = ({
  email,
  value,
  ...winner
}) => ({
  email,
  value,
  nftImage: winner.token_url, // TODO - ADD IMAGE WHEN API GETS AN UPDATE
});
