import { useGetWinners } from 'api/hooks/winners/useGetWinners';
import { Typography, styled, Skeleton } from '@mui/material';
import { winnerToWinnerTile } from 'components/WinnerTile/toWinnerTileProps';
import { createNOf } from 'components/helpers/createNOf';
import { withCutCorner } from '../Layout/cutCorner';
import { WinnerTile } from '../WinnerTile/WinnerTile';

const LastMonthContainer = withCutCorner(
  styled('div')(({ theme: { palette, spacing, breakpoints } }) => ({
    background: palette.background.paperVariant,
    borderBottom: `20px solid ${palette.primary.main}`,
    padding: spacing(4),
    paddingBottom: spacing(2),
    boxSizing: 'border-box',
    '> div': {
      paddingBlock: spacing(2),
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'space-between',
      alignItems: 'center',
      [breakpoints.down(474)]: {
        overflowX: 'auto',
        flexDirection: 'row-reverse',
        '&, & > *': {
          transform: 'rotate(180deg)',
        },
      },
      gap: spacing(2.5),
    },
  })),
);

const LastMonthWinners = () => {
  const { data, isLoading } = useGetWinners();
  const winners = data?.pages[0]?.winners[0]?.winners;
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <LastMonthContainer cornerSize='20px' corner='TR'>
        {isLoading ? (
          <div>
            {createNOf(3, i => (
              <Skeleton key={i} width='150px' height='200px' variant='rounded' />
            ))}
          </div>
        ) : (
          <div>
            {winners !== undefined && winners.length > 0 ? (
              winners.map(winner => <WinnerTile type='horizontal' {...winnerToWinnerTile(winner)} key={winner.email} />)
            ) : (
              <Typography variant='headlineLarge'>No winners for past month!</Typography>
            )}
          </div>
        )}
      </LastMonthContainer>
    </div>
  );
};

export default LastMonthWinners;
