import { ThemeOptions } from '@mui/material/styles';
import { blends, common, commonPalette, gradients } from './common';
import { error, neutral, neutralVariant, primary, secondary, tetriary } from './colors';

const getTextColorVariants = (textColor: string) => {
  if (!/^#[0-9a-f]{6}$/i.test(textColor)) {
    return {
      primary: textColor,
    };
  }
  return {
    primary: `${textColor}FF`,
    secondary: `${textColor}B2`,
    disabled: `${textColor}7F`,
    icon: `${textColor}7F`,
  };
};

export const lightThemeOptions: ThemeOptions = {
  ...common,
  palette: {
    ...commonPalette,
    mode: 'light',
    primary: {
      main: primary[50],
    },
    secondary: {
      main: secondary[50],
    },
    error: {
      main: error[50],
    },
    tetriary: {
      light: tetriary[70],
      main: tetriary[50],
      dark: tetriary[40],
      contrastText: tetriary[100],
    },
    contrast: {
      dark: neutral[0],
      light: neutral[20],
      main: neutral[10],
      contrastText: neutral[100],
    },
    text: getTextColorVariants(neutral[10]),
    background: {
      default: neutral[100],
      paper: neutral[99],
      paperVariant: neutralVariant[95],
    },
    warning: {
      main: '#FEB545',
      contrastText: neutral[100],
    },
    success: {
      main: '#33BA59',
      contrastText: neutral[100],
    },
    gradients: gradients(neutralVariant[95]),
    blends: blends({
      defaultBackground: neutralVariant[99],
      blendColor: neutralVariant[20],
    }),
  },
};

if (lightThemeOptions.palette) lightThemeOptions.palette.info = lightThemeOptions.palette?.tetriary;

export const darkThemeOptions: ThemeOptions = {
  ...common,
  palette: {
    ...commonPalette,
    mode: 'dark',
    primary: {
      main: primary[50],
    },
    secondary: {
      main: secondary[70],
    },
    error: {
      main: error[70],
      contrastText: tetriary[100],
    },
    tetriary: {
      light: tetriary[80],
      main: tetriary[70],
      dark: tetriary[60],
      contrastText: tetriary[100],
    },
    contrast: {
      dark: neutral[90],
      light: neutral[100],
      main: neutral[95],
      contrastText: neutral[0],
    },
    text: getTextColorVariants(neutral[95]),
    background: {
      default: neutral[10],
      paper: neutral[20],
      paperVariant: neutralVariant[20],
    },
    warning: {
      main: '#FEB545',
      contrastText: neutral[100],
    },
    success: {
      main: '#33BA59',
      contrastText: neutral[100],
    },
    gradients: gradients(neutralVariant[10]),
    blends: blends({
      defaultBackground: neutral[10],
      blendColor: neutralVariant[0],
    }),
  },
};

if (darkThemeOptions.palette) darkThemeOptions.palette.info = darkThemeOptions.palette?.tetriary;
