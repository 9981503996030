import { useState } from 'react';
import { useGetNominations } from 'api/hooks/nominations/useGetNominations';

export const useNominationsData = () => {
  const [selectedUser, setSelectedUser] = useState<string | null>();

  const { nominations, isLoading } = useGetNominations();

  const title = nominations?.nomination_period.name || '';
  const nominatedUsers = nominations?.nominated_users || [];
  const userNominations = nominations?.nominations || [];

  const showNominationsDetails = (user: string) => {
    setSelectedUser(user);
  };

  const countNominations = (user: string): string => {
    const count = userNominations.filter(item => item.nominee_email === user)?.length ?? 0;
    return count.toString();
  };

  return {
    title,
    showNominationsDetails,
    countNominations,
    nominatedUsers,
    setSelectedUser,
    selectedUser,
    userNominations,
    isLoading,
  };
};
