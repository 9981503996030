export function getFirstLastName(personName: string | null | undefined): [string, string] | [] {
  if (!personName) return [];

  const fullName = personName.split('@');
  if (!fullName || fullName.length <= 0) return [];

  const splitName = fullName[0].split('.');
  return [splitName[0], splitName[1]];
}

export const getInitials = (personName: string): string => {
  const splitName = getFirstLastName(personName);
  if (splitName.length === 0) return '';
  const userInitials = splitName.length > 1 ? splitName[0][0] + splitName[1][0] : splitName[0][0] + splitName[0][1];
  return userInitials.toUpperCase();
};
