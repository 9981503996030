import { Client } from '../client';
import { EditableSettings, MySettingsResponse } from './typings';

class SettingsController {
  static client = Client;

  static getSettings(): Promise<MySettingsResponse> {
    return this.client.get('/me');
  }

  static setSettings(data: EditableSettings): Promise<void> {
    return this.client.put('/me', data);
  }
}

export { SettingsController };
