import Button, { type ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import WalletIcon from '@mui/icons-material/Wallet';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { useSnackbarMessages } from 'utils/snackbarMessages';

const RoutingButton = styled(({ children, ...props }: ButtonProps) => (
  <Button variant='outlined' {...props}>
    {children}
  </Button>
))(({ theme: { palette } }) => ({
  borderColor: palette.background.paperVariant,
  color: palette.neutral[100],
  '&:hover': {
    borderColor: palette.neutral[100],
  },
}));

export const NFTRoutingButtons = ({ isDesktop = false }) => {
  const { comingSoon } = useSnackbarMessages();
  const handleClick = () => {
    window.open(
      'https://solscan.io/collection/006dbf70cbaa8e63b868a2c7979eb13c369eb28deb3f9a5a7248134695094ad6',
      '_blank',
    );
  };

  return (
    <div className='NFT-gallery__routing-container'>
      <RoutingButton
        className='NFT-gallery__button--All'
        onClick={handleClick}
        startIcon={<WalletIcon color='primary' />}>
        Full{isDesktop ? ' Collection' : ''}
      </RoutingButton>
      <RoutingButton
        className='NFT-gallery__button--VR'
        onClick={comingSoon}
        startIcon={<ViewInArIcon color='primary' />}>
        VR/AR{isDesktop ? ' version' : ''}
      </RoutingButton>
    </div>
  );
};
