import { PeriodsResponse } from 'api/controllers/typings';

export const startOfToday = new Date().setUTCHours(0, 0, 0, 0);

export const getIsEnabledAndStartDate = (date: string) => ({
  isEnabled: new Date(date).getTime() <= startOfToday,
  startDate: new Date(date),
});

export const calculateProgress = (data: PeriodsResponse) => {
  const nominationStart = new Date(data.nomination_period.start_date).setHours(0, 0, 0, 0);
  const votingStart = new Date(data.voting_period.start_date).setHours(0, 0, 0, 0);
  const votingEnd = new Date(data.voting_period.end_date).setHours(0, 0, 0, 0);
  if (startOfToday < nominationStart) return 0;
  if (startOfToday < votingStart) return (50 * (startOfToday - nominationStart)) / (votingStart - nominationStart);
  if (startOfToday < votingEnd) return 50 * (1 + (startOfToday - votingStart) / (votingEnd - votingStart));
  return 100;
};
