import { Client } from 'api/client';
import { generatePaginationQueryParams } from 'api/helpers/paginationQuery';
import { NominationData, NominationsResponse, PaginatedNominationsResponse, PaginationOptions } from './typings';

class NominationsController {
  static client = Client;

  static getNominations(): Promise<NominationsResponse> {
    return this.client.get('/nominations');
  }

  static addNomination(data: NominationData): Promise<void> {
    return this.client.post('/nominations', data);
  }

  static getSentNominations({
    exclusiveStartKey,
    limit = 10,
  }: PaginationOptions): Promise<PaginatedNominationsResponse> {
    return this.client.get(`/nominations/sent?${generatePaginationQueryParams({ exclusiveStartKey, limit })}`);
  }

  static getReceivedNominations({
    exclusiveStartKey,
    limit = 10,
  }: PaginationOptions): Promise<PaginatedNominationsResponse> {
    return this.client.get(`/nominations/received?${generatePaginationQueryParams({ exclusiveStartKey, limit })}`);
  }
}

export { NominationsController };
