import MuiDialog from '@mui/material/Dialog';
import { Box, DialogContent, type Theme, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { DarkModeSwitch } from 'components/DarkModeSwitch';
import { useDrawerContext } from 'providers/drawers/provider';
import { withClose } from '../withClose';
import { ConnectPhantomRow } from './ConnectPhantomRow';
import { SetKeyPreferencesRow } from './SetKeyPreferencesRow';
import { SettingsActions } from './SettingsActions';
import { SettingsContextProvider } from './SettingsContext';

const Dialog = withClose(MuiDialog);

export const SettingsDialog = () => {
  const {
    state: {
      settings: { isOpen, close },
    },
  } = useDrawerContext();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Dialog open={isOpen} onClose={close} maxWidth='md' scroll='paper' fullWidth={!isSmall} fullScreen={isSmall}>
      <SettingsContextProvider>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: 5, alignItems: 'flex-start', padding: '0 40px' }}>
          <Typography variant={isSmall ? 'displayMedium' : 'displayLarge'}>Settings</Typography>
          <ConnectPhantomRow />
          <Divider />
          <SetKeyPreferencesRow />
          <Divider />
          <Box display='flex' justifyContent='flex-start' alignItems='center' typography='titleMedium'>
            Dark Mode <DarkModeSwitch />
          </Box>
          <Divider />
        </DialogContent>
        <SettingsActions close={close} />
      </SettingsContextProvider>
    </Dialog>
  );
};
