import { FC } from 'react';

import Box from '@mui/material/Box';
import { NomineeTable } from 'components/Nominee/NomineeTable';

export const NomineePage: FC = () => (
  <Box
    sx={{
      pt: 8,
      pb: 6,
    }}>
    <NomineeTable title='Nominations' />
  </Box>
);
