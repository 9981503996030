import { FC } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { DarkModeProvider } from 'providers/darkMode/provider';
import A4BEEThemeProvider from 'providers/darkMode/injectTheme';
import { MyQueryClientProvider } from 'api/client';
import { ThemedSnackbarProvider } from 'providers/ThemedSnackbarProvider';
import { UserDataProvider } from 'providers/user/provider';
import { ConfirmationModalProvider } from 'providers/confirmationModal/provider';
import { AuthProvider } from './providers/auth/provider';
import { Router } from './Router';
import { Compose } from './utils/Compose';
import { DrawerProvider } from './providers/drawers/provider';

export const App: FC = () => (
  <Compose
    items={[
      DarkModeProvider,
      A4BEEThemeProvider,
      ThemedSnackbarProvider,
      MyQueryClientProvider,
      AuthProvider,
      UserDataProvider,
      ConfirmationModalProvider,
      DrawerProvider,
    ]}>
    <CssBaseline />
    <Router />
  </Compose>
);

export default App;
