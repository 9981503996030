import Box from '@mui/material/Box';
import ConnectPhantomButton from 'components/Phantom/ConnectPhantom';
import { useSettingsContext } from './SettingsContext';

export const ConnectPhantomRow = () => {
  const {
    state: { isVertical, localSettings },
    actions: { setPublicKey },
  } = useSettingsContext();
  return (
    <Box
      typography='titleMedium'
      display='flex'
      gap='30px'
      alignItems={isVertical ? 'flex-start' : 'center'}
      flexDirection={isVertical ? 'column' : undefined}>
      Connect with Phantom App
      <ConnectPhantomButton onPublicKeyChange={setPublicKey} disabled={localSettings?.no_wallet_specified ?? false} />
    </Box>
  );
};
