export type MuiColor = {
  [grad in 0 | 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90 | 95 | 99 | 100]: string;
};

export const neutral: MuiColor = {
  0: '#000000',
  10: '#242424',
  20: '#303030',
  30: '#474747',
  40: '#5F5F5F',
  50: '#777777',
  60: '#929292',
  70: '#ADADAD',
  80: '#C9C9C9',
  90: '#D6D6D6',
  95: '#E4E4E4',
  99: '#F1F1F1',
  100: '#FFFFFF',
};

export const neutralVariant: MuiColor = {
  0: '#000000',
  10: '#2E3535',
  20: '#3C4545',
  30: '#586464',
  40: '#738484',
  50: '#8FA3A3',
  60: '#A5B5B5',
  70: '#BCC8C8',
  80: '#D2DADA',
  90: '#DDE3E3',
  95: '#E9EDED',
  99: '#F4F6F6',
  100: '#FFFFFF',
};

export const primary: MuiColor = {
  0: '#000000',
  10: '#D2B00E',
  20: '#D8B613',
  30: '#E5C21C',
  40: '#F2CF26',
  50: '#FFDB2F',
  60: '#FFE259',
  70: '#FFE982',
  80: '#FFF1AC',
  90: '#FFF4C1',
  95: '#FFF8D5',
  99: '#FFFBEA',
  100: '#FFFFFF',
};

export const secondary: MuiColor = {
  0: '#000000',
  10: '#00182F',
  20: '#00203F',
  30: '#002F5F',
  40: '#003F7E',
  50: '#004F9E',
  60: '#3372B1',
  70: '#6695C5',
  80: '#99B9D8',
  90: '#B3CAE2',
  95: '#CCDCEC',
  99: '#E6EDF5',
  100: '#FFFFFF',
};

export const tetriary: MuiColor = {
  0: '#000000',
  10: '#3E0516',
  20: '#53071D',
  30: '#7C0B2B',
  40: '#A60E3A',
  50: '#CF1248',
  60: '#D9416D',
  70: '#E27191',
  80: '#ECA0B6',
  90: '#F1B8C8',
  95: '#F5D0DA',
  99: '#FAE7ED',
  100: '#FFFFFF',
};

export const error: MuiColor = {
  0: '#000000',
  10: '#440C0B',
  20: '#5A100F',
  30: '#871717',
  40: '#B41F1E',
  50: '#E12726',
  60: '#E75251',
  70: '#ED7D7D',
  80: '#F3A9A8',
  90: '#F6BEBE',
  95: '#F9D4D4',
  99: '#FCE9E9',
  100: '#FFFFFF',
};
