import useMediaQuery from '@mui/material/useMediaQuery';
import { DarkModeSwitch } from 'components/DarkModeSwitch';
import Timeline from 'components/Timeline/Timeline';
import Box from '@mui/material/Box';
import type { Theme } from '@mui/material/styles';
import { MenuButtons } from './MenuButtons';
import { BeeButton } from '../Header/BeeButton';
import { UserGreeting } from '../Header/UserGreeting';

export const DesktopMenu = () => (
  <Box display='flex' alignItems='center' justifyContent='space-between' height='160px' gap='1rem'>
    <Box display='flex' alignItems='flex-end' gap={1.25} minWidth='max-content' width='100%'>
      <BeeButton style={{ marginBottom: '-10px' }} />
      <MenuButtons type='icon' display='flex' alignItems='center' gap={2} />
      <UserGreeting />
    </Box>
    <Box display='flex' gap='1.5rem' alignItems='center' width='100%'>
      <Timeline
        minWidth='270px'
        maxWidth='700px'
        verticalLabels={useMediaQuery((theme: Theme) => theme.breakpoints.down(1162))}
      />
      <DarkModeSwitch />
    </Box>
  </Box>
);
