import { FC } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

interface ContentLoaderProps {
  isLoaded: boolean;
  children?: React.ReactNode;
}

export const ContentLoader: FC<ContentLoaderProps> = ({ children, isLoaded }) => (
  <>
    {isLoaded && children}
    {!isLoaded && (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxSizing: 'border-box',
        }}>
        <CircularProgress color='primary' />
      </Box>
    )}
  </>
);
