import { forwardRef } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import { type ModalProps } from '@mui/material/Modal';

type PassThroughProps = {
  children?: React.ReactNode;
  onClose?: ModalProps['onClose'];
};

export function withClose<T extends PassThroughProps = PassThroughProps>(WrapperComponent: React.ComponentType<T>) {
  return forwardRef(({ children, onClose, ...props }: T, ref) => (
    <WrapperComponent {...(props as unknown as T)} onClose={onClose} ref={ref}>
      <DialogTitle sx={{ height: '75px', boxSizing: 'border-box', p: 2.5 }}>
        <IconButton
          onClick={() => onClose?.({}, 'backdropClick')}
          color='inherit'
          sx={{ marginLeft: 'auto', display: 'flex' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {children}
    </WrapperComponent>
  ));
}
