import { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { ReactComponent as PhantomLogo } from 'assets/phantom-icon-purple.svg';
import { SolanaProvider, WalletAction } from './typings';

export type ConnectPhantomProps = {
  onPublicKeyChange: (key: string | undefined) => void;
  disabled?: boolean;
};

const PhantomButton = styled(LoadingButton)(({ theme }) => ({
  font: '500 20px/1.2 Roboto, sans-serif',
  borderRadius: '10px',
  background: theme.palette.neutral[100],
  '&:hover, &:focus-visible, &:target': {
    background: theme.palette.neutral[100],
    boxShadow: theme.shadows[10],
  },
}));

// eslint-disable-next-line @typescript-eslint/no-empty-function
const ConnectPhantomButton = ({ onPublicKeyChange = () => {}, disabled }: ConnectPhantomProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { solana } = window as typeof window & { solana?: SolanaProvider };

  const walletAction =
    ({ solanaAction, action }: WalletAction) =>
    () => {
      if (solana && solana.isPhantom) {
        setLoading(true);
        solana[solanaAction]()
          .then(action)
          .catch(console.error) // eslint-disable-line no-console
          .finally(() => setLoading(false));
      } else {
        enqueueSnackbar('Phantom wallet not found', {
          variant: 'info',
        });
      }
    };

  const connectWallet = walletAction({
    solanaAction: 'connect',
    action: response => {
      if (response) {
        onPublicKeyChange(response.publicKey.toString());
      }
    },
  });

  return (
    <PhantomButton
      variant='contained'
      startIcon={<PhantomLogo />}
      loading={loading}
      disabled={disabled}
      onClick={connectWallet}>
      Phantom
    </PhantomButton>
  );
};

export default ConnectPhantomButton;
