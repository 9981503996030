import { Client } from '../client';
import type { CurrentVotingStatisticsResponse, PreviousVotingStatisticsResponse } from './typings';

class StatisticsController {
  static client = Client;

  static getCurrentVotingStatistics(): Promise<CurrentVotingStatisticsResponse> {
    return this.client.get('/votings/current/stats');
  }

  static getPreviousVotingStatistics(): Promise<PreviousVotingStatisticsResponse> {
    return this.client.get('/votings/previous/stats');
  }
}

export { StatisticsController };
