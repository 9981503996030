import { LinearProgress, LinearProgressProps, linearProgressClasses, styled } from '@mui/material';
import { useGetPeriod } from 'api/hooks/status/useGetPeriod';
import { PeriodsResponse } from 'api/controllers/typings';
import { useState } from 'react';
import { PeriodAvatar } from './PeriodAvatar';
import { PeriodData } from './typings';
import { calculateProgress, getIsEnabledAndStartDate } from './formatData';

const { min, max } = Math;

export type TimelineProps = {
  minWidth?: string;
  maxWidth?: string;
  verticalLabels?: boolean;
};

const VotingProgress = styled((props: LinearProgressProps) => (
  <LinearProgress {...props} variant='determinate' color='secondary' />
))(
  ({
    theme: {
      palette: { background },
    },
  }) => ({
    [`&.${linearProgressClasses.colorSecondary}`]: {
      backgroundColor: background.paperVariant,
    },
    width: '100%',
    height: '8px',
    margin: '0 -2px',
    zIndex: 0,
  }),
);

const Timeline: React.FC<TimelineProps> = ({ minWidth, maxWidth, verticalLabels = false }) => {
  const [progress, setProgress] = useState(0);
  const [enablePeriods, setEnablePeriods] = useState<Partial<PeriodData>>({
    nominate: undefined,
    vote: undefined,
    result: undefined,
  });

  const initializeTimeline = (data: PeriodsResponse) => {
    setProgress(calculateProgress(data));
    setEnablePeriods({
      nominate: getIsEnabledAndStartDate(data.nomination_period.start_date),
      vote: getIsEnabledAndStartDate(data.voting_period.start_date),
      result: getIsEnabledAndStartDate(data.voting_period.end_date),
    });
  };
  useGetPeriod({
    onSuccess: initializeTimeline,
  });

  return (
    <div
      style={{
        minWidth,
        maxWidth,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        isolation: 'isolate',
        ...(verticalLabels
          ? {
              paddingTop: '25px',
            }
          : {
              paddingRight: '80px',
            }),
        width: '100%',
      }}>
      <PeriodAvatar {...enablePeriods.nominate} periodName='nominate' verticalLabels={verticalLabels} />
      <VotingProgress value={min(progress, 50) * 2} />
      <PeriodAvatar {...enablePeriods.vote} periodName='vote' verticalLabels={verticalLabels} />
      <VotingProgress value={max(progress - 50, 0) * 2} />
      <PeriodAvatar {...enablePeriods.result} periodName='result' verticalLabels={verticalLabels} />
    </div>
  );
};

export default Timeline;
