import { MOCK_API } from '../../configs/environment';
import { client, RequestConfig } from '../configs/client';
import { mock } from '../configs/mock';

class Client {
  static setupClient(url: string): void {
    client.defaults.baseURL = url;

    if (MOCK_API) {
      mock(url);
    }
  }

  static async get<T>(url: string, config?: RequestConfig): Promise<T> {
    const response = await client.get<T>(url, config);
    return response?.data;
  }

  static async post<T, D = void>(url: string, data?: D, config?: RequestConfig): Promise<T> {
    const response = await client.post<T>(url, data, config);
    return response?.data;
  }

  static async put<T, D>(url: string, data: D, config?: RequestConfig): Promise<T> {
    const response = await client.put<T>(url, data, config);
    return response?.data;
  }

  static async patch<T, D>(url: string, data: D, config?: RequestConfig): Promise<T> {
    const response = await client.patch<T>(url, data, config);
    return response?.data;
  }

  static async delete<T>(url: string, config?: RequestConfig): Promise<T> {
    const response = await client.delete<T>(url, config);
    return response?.data;
  }
}

export { Client };
