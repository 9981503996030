import React, { useMemo, useCallback } from 'react';
import { useGetUsers } from 'api/hooks/users/useGetUsers';
import getImageSource from 'components/helpers/imagebase64';
import { useExistingContext } from 'providers/useExistingContext';
import UserDataContext from './context';

export const UserDataProvider = ({ children }: { children?: React.ReactNode }) => {
  const { users } = useGetUsers();
  const usersWithImages = useMemo(
    () =>
      users?.map(({ photo_data: photoData, ...user }) => ({
        photo_data: getImageSource(photoData),
        ...user,
      })),
    [users],
  );
  const getUserByEmail = useCallback(
    (email: string) => usersWithImages?.find(user => user.email === email),
    [usersWithImages],
  );

  const value = useMemo(
    () => ({
      users: usersWithImages,
      getUserByEmail,
    }),
    [users],
  );

  return <UserDataContext.Provider value={value}>{children}</UserDataContext.Provider>;
};

export const useUserData = () => useExistingContext(UserDataContext);
