import { QueryKey, useQueryClient, useMutation, UseMutationOptions } from 'react-query';
import { BasicQueryOptions } from './typings';

type UseTemplateMutation<Payload, Response = void> = {
  mutationFn: (payload: Payload) => Promise<Response>;
  refetchQueries?: QueryKey[];
  options?: Omit<UseMutationOptions<Response, Response, Payload>, 'refetchQueries, onSuccess, onError, mutationFn'>;
};

export function useTemplateMutation<Payload, Response = void>({
  mutationFn,
  refetchQueries,
  options,
}: UseTemplateMutation<Payload, Response>) {
  return ({ onSuccess, onError }: BasicQueryOptions<Payload> = {}) => {
    const queryClient = useQueryClient();
    return useMutation(mutationFn, {
      onSuccess(_, variables) {
        if (refetchQueries !== undefined && refetchQueries.length > 0)
          queryClient.refetchQueries(refetchQueries).then(() => {
            onSuccess?.(variables);
          });
        else onSuccess?.(variables);
      },
      onError,
      ...options,
    } as Omit<UseMutationOptions<Response, Response, Payload>, 'refetchQueries, mutationFn'>);
  };
}
