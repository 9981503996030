import type { CSSObject, Theme } from '@mui/material/styles';
import { SectionBox } from 'components/Layout/SectionBox';
import { useGetSentNominations, useGetReceivedNominations } from 'api/hooks/nominations';
import { NominationsList } from './NominationsList';

const styling: (theme: Theme) => CSSObject = theme => ({
  display: 'flex',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  '& > *': {
    flexGrow: 1,
  },
});

export const NominationsBox = () => {
  const {
    data: sentData,
    isLoading: isSentNominationsLoading,
    fetchNext: fetchSent,
    hasMore: hasMoreSent,
  } = useGetSentNominations();

  const {
    data: receivedData,
    isLoading: isReceivedNominationsLoading,
    fetchNext: fetchReceived,
    hasMore: hasMoreReceived,
  } = useGetReceivedNominations();

  const sentNominations = sentData?.pages.flatMap(page => page.nominations) ?? [];
  const receivedNominations = receivedData?.pages.flatMap(page => page.nominations) ?? [];

  const isAnyLoading = isSentNominationsLoading || isReceivedNominationsLoading;
  const isLoadingOrHasSent = sentNominations.length > 0 || isAnyLoading;
  const isLoadingOrHasReceived = receivedNominations.length > 0 || isAnyLoading;

  return (
    <SectionBox muiStyling={styling} pad>
      {isLoadingOrHasSent && (
        <NominationsList
          title='Nominations Sent'
          columns={isLoadingOrHasReceived ? 1 : 2}
          nominations={sentNominations}
          hasMore={hasMoreSent ?? false}
          fetchNext={fetchSent}
          loading={isSentNominationsLoading}
        />
      )}
      {isLoadingOrHasReceived && (
        <NominationsList
          title='Nominations Received'
          columns={isLoadingOrHasSent ? 1 : 2}
          nominations={receivedNominations}
          hasMore={hasMoreReceived ?? false}
          fetchNext={fetchReceived}
          loading={isReceivedNominationsLoading}
        />
      )}
    </SectionBox>
  );
};
