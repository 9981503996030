import { LoadingIconButton } from 'components/FilledIconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

type SliderArrow = {
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
};

export type SliderArrowsProps = {
  prev?: SliderArrow;
  next?: SliderArrow;
  className?: string;
  arrowClassName?: string;
};

const styles: React.CSSProperties = {
  display: 'flex',
  gap: '1em',
};

const SliderArrows = ({ prev, next, arrowClassName, ...props }: SliderArrowsProps) => {
  return (
    <div style={styles} {...props}>
      <LoadingIconButton className={arrowClassName} {...prev} filled>
        <ArrowBackIcon />
      </LoadingIconButton>
      <LoadingIconButton className={arrowClassName} {...next} filled>
        <ArrowForwardIcon />
      </LoadingIconButton>
    </div>
  );
};

export default SliderArrows;
