import { Button, Container, containerClasses, styled } from '@mui/material';
import CelebrationIcon from '@mui/icons-material/Celebration';
import { useSnackbarMessages } from 'utils/snackbarMessages';
import { withCutCorner } from 'components/Layout/cutCorner';
import { useGetWinners } from 'api/hooks/winners';
import Typography from '@mui/material/Typography';
import LastMonthWinners from './LastMonthWinners';
import { PaginatedWinnersList } from './PaginatedWinnersList';
import { gradientSectionStyles } from '../Layout/GradientSection';

const headlineClass = 'winner-list__headline';

const WinnerContainer = withCutCorner(
  styled('div')(({ theme }) => ({
    ...gradientSectionStyles({ theme }),
    background: theme.palette.gradients.bottom({ startOpacity: 50 }),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(6),
    [`& .${containerClasses.root}`]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      gap: theme.spacing(3),
      [theme.breakpoints.down(1438)]: {
        display: 'flex',
        flexDirection: 'column',
        '& .winner-list__paginated--container': {
          width: 'calc(50% + 50vw)',
        },
      },
    },
    '& h2': {
      color: theme.palette.neutralVariant[theme.palette.mode === 'dark' ? 50 : 60],
      marginBottom: '6px',
    },
    [`& .${headlineClass}`]: {
      '& > button': {
        marginLeft: 'auto',
        display: 'flex',
      },
      [theme.breakpoints.up(400)]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        '& > button': {
          marginLeft: 'none',
        },
      },
      gridColumn: 'span 2',
    },
  })),
);

function getTitleText(isLoading: boolean, beeMonth: string | undefined) {
  if (isLoading) return 'Loading...';
  if (beeMonth) return `Bees of the ${beeMonth}!`;
  return 'No bees found :(';
}

export const WinnerList = () => {
  const { comingSoon } = useSnackbarMessages();
  const { data, isLoading } = useGetWinners();
  const beeMonth = data?.pages[0].winners[0].voting_id.split('-')[0];
  return (
    <WinnerContainer>
      <Container>
        <div className={headlineClass}>
          <Typography variant='headlineLarge' component='h2'>
            {getTitleText(isLoading, beeMonth)}
          </Typography>
          <Button
            onClick={comingSoon}
            variant='outlined'
            color='contrast'
            sx={{ flexShrink: 0 }}
            startIcon={<CelebrationIcon />}>
            All Winners
          </Button>
        </div>
        <LastMonthWinners />
        <PaginatedWinnersList />
      </Container>
    </WinnerContainer>
  );
};
