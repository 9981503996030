import { DialogTitle, DialogContent, DialogContentText, useMediaQuery } from '@mui/material';
import { useConfirmationModal } from './context';
import type { UseConfirmationModalBasic } from './typings';

type ModalTitleAndSubtitleProps = Pick<UseConfirmationModalBasic, 'title' | 'subtitle'>;

const ModalTitleAndSubtitle = ({ title, subtitle }: ModalTitleAndSubtitleProps) => (
  <>
    <DialogTitle typography={useMediaQuery('(width <= 425px)') ? 'displayMedium' : 'displayLarge'} sx={{ px: 7.5 }}>
      {title}
    </DialogTitle>
    {subtitle && (
      <DialogContent color='text.primary' sx={{ p: '16px 60px' }}>
        {typeof subtitle === 'string' ? <DialogContentText marginTop='20px'>{subtitle}</DialogContentText> : subtitle}
      </DialogContent>
    )}
  </>
);

export const useConfirmationModalBasic = () => {
  const confirm = useConfirmationModal();
  return ({ title, subtitle, ...props }: UseConfirmationModalBasic) =>
    confirm({
      content: <ModalTitleAndSubtitle title={title} subtitle={subtitle} />,
      ...props,
    });
};
