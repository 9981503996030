import { User } from '../typings';

export const MOCK_USERS: User[] = [
  {
    email: 'test@test.com',
    first_name: 'test',
    last_name: 'test',
    public_key: null,
    no_wallet_specified: false,
    photo_data: 'test',
  },
  {
    email: 'test1@test.com',
    first_name: 'test1',
    last_name: 'test1',
    public_key: null,
    no_wallet_specified: false,
    photo_data: 'test1',
  },
];
