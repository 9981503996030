import { List, ListItem, styled } from '@mui/material';

export type OrderedListProps = {
  children: React.ReactElement[];
};

const counterSize = '2em';

const common = {
  position: 'absolute',
  top: '8px',
  margin: 'auto 0',
  width: counterSize,
  height: counterSize,
};

const OrderedListItem = styled(ListItem)(({ theme }) => ({
  marginLeft: counterSize,
  counterIncrement: 'ordered-list',
  listStyle: 'none',
  position: 'relative',
  isolation: 'isolate',
  width: `calc(100% - ${counterSize})`,
  '&::after': {
    ...common,
    content: '""',
    borderRadius: '100vh',
    borderTopRightRadius: 0,
    transform: 'translateX(-150%) rotate(45deg)',
    transformOrigin: 'center center',
    backgroundColor: theme.palette.primary.main,
  },
  '&::before': {
    ...common,
    fontWeight: 700,
    textAlign: 'center',
    verticalAlign: 'middle',
    lineHeight: '2em',
    zIndex: 1,
    content: 'counter(ordered-list, decimal-leading-zero)',
    color: theme.palette.primary.contrastText,
    transform: 'translateX(-150%)',
  },
}));

export const OrderedList: React.FC<OrderedListProps> = ({ children }) => (
  <List sx={{ counterReset: 'ordered-list' }}>
    {children.map((child, index) => (
      <OrderedListItem key={index}>{child}</OrderedListItem>
    ))}
  </List>
);
