import { User } from '../typings';

export const MOCK_USERS_ITEM: User = {
  email: 'test@test.com',
  first_name: 'test',
  last_name: 'test',
  public_key: null,
  no_wallet_specified: false,
  photo_data: 'test',
};
