import { type FC, type HTMLAttributes, useRef, useMemo, useState } from 'react';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled, type Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGetTokens } from 'api/hooks/useGetTokens';
import { WinnerTile } from 'components/WinnerTile/WinnerTile';
import Slider, { type SliderRef } from 'components/Slider/Slider';
import SliderArrows from 'components/Slider/SliderArrows';
import { useCutCorner } from 'components/Layout/cutCorner';
import { NFTRoutingButtons } from './NFTRoutingButtons';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { tokenToWinnerTile } from '../WinnerTile/toWinnerTileProps';

const ControlsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '34px',
  paddingBottom: '61px',
  position: 'relative',
  [theme.breakpoints.down(400)]: {
    flexDirection: 'row-reverse',
    gap: 8,
    '& .NFT-gallery__button--VR': {
      display: 'none',
    },
  },
  '&:after': {
    position: 'absolute',
    left: 0,
    bottom: 0,
    content: '""',
    width: '100vw',
    height: 0,
    borderBottom: `1px solid ${theme.palette.neutralVariant[10]}`,
  },
}));

const GalleryContainer = styled(({ children, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div {...props}>
    <div {...useCutCorner()}>
      <Container>{children}</Container>
    </div>
  </div>
))(({ theme: { palette, typography } }) => ({
  position: 'relative',
  '& > div': {
    backgroundColor: palette.neutral[0],
    paddingTop: `calc(144px - ${typography.headlineLarge.fontSize}px - 2rem)`,
    overflow: 'hidden',
  },
  '& .NFT-slider': {
    width: '100vw',
    marginLeft: '50%',
    transform: 'translateX(-50%)',
  },
  '&:after': {
    // covers the cut corner in footer
    content: '""',
    position: 'absolute',
    bottom: '-64px',
    left: 0,
    height: 0,
    width: 0,
    border: 'solid 32px transparent',
    borderTopColor: palette.neutral[0],
    borderLeftColor: palette.neutral[0],
  },
}));

const SliderIndicator = styled('div')(({ theme }) => ({
  position: 'relative',
  top: '-2rem',
  width: '100%',
  backgroundColor: theme.palette.neutralVariant[theme.palette.mode === 'dark' ? 30 : 80],
  '&, & > div': {
    borderRadius: '100vmax',
  },
  '& > div': {
    transition: 'left 0.2s ease-in-out',
    height: '5px',
    backgroundColor: theme.palette.success.main,
  },
}));

export const NFTGallery: FC = () => {
  const sliderRef = useRef<SliderRef>(null);
  const { tokens: tokenArr } = useGetTokens();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up(500));
  const [current, setCurrent] = useState(0);
  const arrowsProps = useMemo(
    () => ({
      prev: {
        onClick: sliderRef.current?.prev,
      },
      next: {
        onClick: sliderRef.current?.next,
      },
    }),
    [sliderRef.current],
  );
  const slidePercentage = 100 / (tokenArr?.length ? tokenArr.length : 1);

  return (
    <GalleryContainer>
      <Typography display='block' variant='headlineLarge' color='background.paperVariant' mb='2rem'>
        Our TechHive Gallery
      </Typography>
      <SliderIndicator>
        <div style={{ width: `${slidePercentage}%`, position: 'relative', left: `${current * slidePercentage}%` }} />
      </SliderIndicator>
      <Slider
        className='NFT-slider'
        ref={sliderRef}
        centerMode={isDesktop}
        beforeChange={(_, next) => setCurrent(next)}>
        {tokenArr?.map(token => (
          <WinnerTile key={token.id} {...tokenToWinnerTile(token)} type='NFTImage' />
        ))}
      </Slider>
      <ControlsContainer>
        <NFTRoutingButtons isDesktop={isDesktop} />
        <SliderArrows {...arrowsProps} />
      </ControlsContainer>
    </GalleryContainer>
  );
};
