import { useQuery } from 'react-query';
import { NominationsController } from 'api/controllers/NominationController';
import { NominationsResponse } from 'api/controllers/typings';
import { GET_NOMINATIONS } from '../queryKeys';
import { BasicQueryOptions } from '../typings';

export const useGetNominations = ({ onSuccess, onError }: BasicQueryOptions<NominationsResponse> = {}) => {
  const { data, ...rest } = useQuery({
    queryKey: [GET_NOMINATIONS],
    queryFn: () => NominationsController.getNominations(),
    onSuccess,
    onError,
  });
  return {
    nominations: data,
    ...rest,
  };
};
