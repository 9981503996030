import { useExistingContext } from 'providers/useExistingContext';
import { createContext } from 'react';

export type DarkModeType = {
  darkMode: boolean;
  toggleDarkMode: () => void;
};

export const DarkModeContext = createContext<DarkModeType>({
  darkMode: false,
  toggleDarkMode: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
});

export const useDarkMode = () => useExistingContext(DarkModeContext);
