import { createElement } from 'react';

type ComponentConstructor<P = Record<string, never>> = React.JSXElementConstructor<React.PropsWithChildren<P>>;
type ComposedComponentWithProps<P> = [ComponentConstructor<P>, Omit<P, 'children'>];

export type ComposeProps<T extends unknown[]> = {
  items: {
    [K in keyof T]: T[K] extends [ComponentConstructor<infer P>, ...unknown[]]
      ? ComposedComponentWithProps<P>
      : ComponentConstructor;
  };
  children: React.ReactElement[];
};
export const Compose = <T extends unknown[]>({ items, children }: ComposeProps<T>) =>
  items.reduceRight((composeTree: React.ReactElement | React.ReactElement[], current) => {
    const componentChildren = Array.isArray(composeTree) ? composeTree : [composeTree];
    if (Array.isArray(current)) {
      return createElement(current[0], current[1], ...componentChildren);
    }
    return createElement(current, undefined, ...componentChildren);
  }, children) as React.ReactElement;
