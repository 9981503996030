import { WinnersListItem } from '../typings';

export const MOCK_WINNERS: WinnersListItem[] = [
  {
    voting_id: '23423423424',
    reward_subject: {
      start_date: '2017-01-01',
      end_date: '2017-01-01',
      name: 'Bee of december',
    },
    winners: [
      {
        email: 'test@test.pl',
        value: '#mocking',
        token_url: null,
      },
      { email: 'test@test.pl', value: '#mocking', token_url: null },
      { email: 'test@test.pl', value: '#mocking', token_url: null },
    ],
  },
  {
    voting_id: '23423423424',
    reward_subject: {
      start_date: '2017-01-01',
      end_date: '2017-01-01',
      name: 'Bee of november',
    },
    winners: [
      { email: 'test@test.pl', value: '#mocking', token_url: null },
      { email: 'test@test.pl', value: '#mocking', token_url: null },
      { email: 'test@test.pl', value: '#mocking', token_url: null },
    ],
  },
  {
    voting_id: '23423423424',
    reward_subject: {
      start_date: '2017-01-01',
      end_date: '2017-01-01',
      name: 'Bee of october',
    },
    winners: [
      { email: 'test@test.pl', value: '#mocking', token_url: null },
      { email: 'test@test.pl', value: '#mocking', token_url: null },
      { email: 'test@test.pl', value: '#mocking', token_url: null },
    ],
  },
  {
    voting_id: '23423423424',
    reward_subject: {
      start_date: '2017-01-01',
      end_date: '2017-01-01',
      name: 'Bee of september',
    },
    winners: [
      { email: 'test@test.pl', value: '#mocking', token_url: null },
      { email: 'test@test.pl', value: '#mocking', token_url: null },
      { email: 'test@test.pl', value: '#mocking', token_url: null },
    ],
  },
  {
    voting_id: '23423423424',
    reward_subject: {
      start_date: '2017-01-01',
      end_date: '2017-01-01',
      name: 'Bee of august',
    },
    winners: [{ email: 'test@test.pl', value: '#mocking', token_url: null }],
  },
];
