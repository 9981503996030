import { styled, TooltipProps, TypographyProps } from '@mui/material';
import { forwardRef } from 'react';
import { Dict } from 'types';

type Directions = 'top' | 'right' | 'left' | 'bottom';
/* eslint-disable react/no-unused-prop-types */
export type PersistantTooltipProps = {
  children: React.ReactNode;
  color?: TooltipProps['color'];
  arrow?: Directions | undefined;
  rotation?: number;
  position:
    | Partial<{
        top: string | number;
        left: string | number;
        bottom: string | number;
        right: string | number;
      }>
    | 'relative'
    | 'nomargin';
  variant?: TypographyProps['variant'];
};
/* eslint-enable react/no-unused-prop-types */

const arrowPadding = 0.6;
const arrowStretch = 2;
const arrowLength = arrowPadding * arrowStretch;
const middleOfBox = (arr: Directions | undefined) => {
  switch (arr) {
    case 'top':
      return `calc(50% + ${arrowPadding}em)`;
    case 'bottom':
      return `calc(50% + ${arrowPadding}em)`;
    default:
      return '50%';
  }
};

const capitalizedDirection = Object.fromEntries(
  (['top', 'right', 'left', 'bottom'] as Array<Directions>).map(direction => [
    direction,
    direction?.charAt(0).toLocaleUpperCase().concat(direction.slice(1)),
  ]),
) as Dict<Directions, string>;

const reverseDirection: Dict<Directions, string> = {
  top: 'Bottom',
  bottom: 'Top',
  left: 'Right',
  right: 'Left',
};

export const PersistantTooltip = styled(
  forwardRef<HTMLDivElement, PersistantTooltipProps>(({ children, ...props }, ref) => {
    return children ? (
      <div {...props} ref={ref}>
        {children}
      </div>
    ) : null;
  }),
  {
    shouldForwardProp: prop => !['color', 'placement', 'position', 'arrow', 'variant'].includes(prop as string),
  },
)<PersistantTooltipProps>(
  ({
    theme: { typography, spacing, palette, shape },
    color = 'secondary',
    position,
    arrow,
    rotation = 0,
    variant = 'titleSmall',
  }) => {
    const transform = `rotate(${rotation}deg)`;
    const positionCoords =
      typeof position === 'object'
        ? Object.fromEntries([
            ['position', 'absolute'],
            ...Object.entries(position).map(([direction, value]) => [
              direction,
              typeof value === 'number' ? spacing(value) : value,
            ]),
          ])
        : {
            position,
          };
    return {
      ...positionCoords,
      padding: spacing(0.5),
      backgroundColor: palette[color]?.main,
      color: palette[color]?.contrastText,
      borderRadius: shape.borderRadius,
      transition: 'transform 2s ease-in-out',
      transformOrigin: `${arrow === 'left' ? `${-arrowLength}em` : 0} ${middleOfBox(arrow)}`,
      transform,
      ...(variant === 'inherit'
        ? {
            fontSize: 'inherit',
          }
        : typography[variant]),
      ...(arrow !== undefined && {
        ...(position !== 'nomargin' && {
          [`margin${capitalizedDirection[arrow]}`]: `${arrowLength}em`,
        }),
        '&:before': {
          color: palette[color]?.main,
          position: 'absolute',
          margin: 'auto',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          fontSize: 'inherit',
          [arrow]: `${-2 * arrowLength + 0.1}em`,
          [reverseDirection[arrow].toLocaleLowerCase()]: 'unset',
          content: '""',
          width: 0,
          height: 0,
          border: `${arrowPadding}em solid transparent`,
          [`border${capitalizedDirection[arrow]}`]: `${arrowLength}em solid transparent`,
          [`border${reverseDirection[arrow]}`]: `${arrowLength}em solid ${palette[color]?.main}`,
        },
      }),
    };
  },
);
