import { Box, Skeleton, styled } from '@mui/material';
import { FC, HTMLAttributes } from 'react';
import { createNOf } from './helpers/createNOf';

export type NumberWithRectanglesProps = {
  num: number | null;
  fontSize?: string;
};

type Transform = `${`translate3d(${string}) ` | ''}${`rotate(${string})` | ''}`;

type RectanglesTransform = {
  light: Transform;
  dark: Transform;
};

const transformArray: RectanglesTransform[] = [
  {
    light: 'translate3d(0, 0, 0) rotate(-5.41deg)',
    dark: 'translate3d(-0.034em, .016em, 0) rotate(-0.98deg)',
  },
  {
    light: 'rotate(5.08deg)',
    dark: 'translate3d(.039em, .039em, 0) rotate(1.96deg)',
  },
  {
    light: 'rotate(-5.41deg)',
    dark: 'translate3d(-0.039em, .016em, 0) rotate(-0.98deg)',
  },
];

const commonStyles = {
  content: '""',
  position: 'absolute',
  top: '10%',
  bottom: '10%',
  left: '0%',
  right: '0%',
};

const SymbolWithRectangles = styled(
  ({ children, ...props }: HTMLAttributes<HTMLDivElement> & { transform?: RectanglesTransform }) => (
    <div {...props}>{children}</div>
  ),
  {
    shouldForwardProp: name => name !== 'transform',
  },
)(({ theme: { palette, shadows }, transform }) => ({
  position: 'relative',
  isolation: 'isolate',
  textAlign: 'center',
  width: '0.7em',
  '&:before': {
    ...(transform?.light && {
      ...commonStyles,
      boxShadow: shadows[3],
      backgroundColor: palette.background.paper,
      transform: transform.light,
      zIndex: -1,
    }),
  },
  '&:after': {
    ...(transform?.dark && {
      ...commonStyles,
      boxShadow: shadows[3],
      ...palette.blends(1, palette.background.paperVariant),
      transform: transform.dark,
      zIndex: -2,
    }),
  },
}));

const getDisplaySymbols = (num: number | null) => {
  if (num === null || Number.isNaN(num)) {
    return undefined;
  }
  if (num > 999) {
    return '999+';
  }
  return Math.max(num, 0).toFixed(0);
};

export const NumberWithRectangles: FC<NumberWithRectanglesProps> = ({ num, fontSize = '1em' }) => {
  const displaySymbols = getDisplaySymbols(num);

  return (
    <Box display='flex' gap='.1em' fontSize={fontSize} textAlign='center' fontWeight='700' lineHeight={1.2}>
      {displaySymbols
        ? displaySymbols.split('').map((symbol, index) => (
            <SymbolWithRectangles key={index} transform={transformArray[index]}>
              {symbol}
            </SymbolWithRectangles>
          ))
        : createNOf(2, index => (
            <SymbolWithRectangles key={index} transform={transformArray[index]}>
              <Skeleton width='.5em' sx={{ m: '0 auto' }} animation='wave' />
            </SymbolWithRectangles>
          ))}
    </Box>
  );
};
