import React from 'react';
import {
  Box,
  Typography,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  avatarClasses,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { PersistantTooltip } from 'components/PersistantTooltip/TopdownTooltip';
import { NominationItem, User } from 'api/controllers/typings';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import HowToVote from '@mui/icons-material/HowToVote';
import { getUserNominations } from './utils';
import { useNominationsData } from './useNominationData';
import { useSnackbarHandlers } from './useSnackbarHandlers';
import { NomineeDialogContent } from './NomineeDialogContent';

interface NomineeRowProps {
  user: User;
  isVote?: boolean;
}

interface NomineeToolTipProps {
  avatar?: string | undefined;
  firstName: string;
  lastName: string;
  nominations: NominationItem[];
}
const layoutSwitchBreakpoint = '430px';
const smallAvatarSize = '75px';

const NomineeTooltip: React.FC<NomineeToolTipProps> = ({ avatar, firstName, lastName, nominations }) => {
  return (
    <Box py='0.7em' minWidth='0px'>
      <Box
        sx={{
          paddingBottom: '0.5em',
          [`@media (width > ${layoutSwitchBreakpoint})`]: {
            paddingBottom: 0,
            '& br': {
              display: 'none',
            },
            [`& .${avatarClasses.root}`]: {
              display: 'none',
            },
          },
        }}
        display='flex'
        justifyContent='space-between'>
        <Typography variant='headlineMedium' display='block' mb={1}>
          {`${firstName} `}
          <br />
          {lastName}
        </Typography>
        <Avatar
          src={avatar}
          variant='hex'
          sx={{
            height: smallAvatarSize,
            width: smallAvatarSize,
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          marginLeft: '1em',
          maxWidth: '23em',
          flexWrap: 'wrap',
          gap: '0.3em',
        }}>
        {nominations.map((item, itemIndex) => (
          <PersistantTooltip
            key={itemIndex}
            variant='titleSmall'
            arrow={itemIndex ? undefined : 'left'}
            position='relative'
            sx={{
              marginLeft: 0,
              width: 'fit-content',
            }}>
            {/* {console.log(item.value)} */}
            {item.value}
          </PersistantTooltip>
        ))}
      </Box>
    </Box>
  );
};

const NomineeRow: React.FC<NomineeRowProps> = ({ user, isVote }) => {
  const { userNominations, showNominationsDetails, selectedUser, setSelectedUser } = useNominationsData();
  const { processing, submitVote } = useSnackbarHandlers();
  const uniqueNominations = getUserNominations(userNominations);
  const allNominations = getUserNominations(userNominations, { mode: 'all' });
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          [`@media (width <= ${layoutSwitchBreakpoint})`]: {
            margin: 'auto',
            width: '100%',
            display: 'inline-flex',
            flexDirection: 'column',
          },
        }}>
        <Avatar
          src={user?.photo_data}
          variant='hex'
          sx={{
            height: '90px',
            width: '90px',
            alignSelf: 'start',
            marginTop: '1.2em',
            marginRight: '1.2em',
            [`@media (width <= ${layoutSwitchBreakpoint})`]: {
              display: 'none',
            },
          }}
        />
        <Box
          display='flex'
          sx={{
            '@media (width <= 710px)': {
              flexDirection: 'column',
              paddingTop: 2,
            },
          }}
          justifyContent='space-between'
          width='100%'
          borderBottom='1px solid grey'>
          <NomineeTooltip
            avatar={user?.photo_data}
            firstName={user.first_name}
            lastName={user.last_name}
            nominations={uniqueNominations[user.email]}
          />
          <Box
            sx={{
              flexShrink: 0,
              display: 'flex',
              paddingBottom: '1.2em',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              gap: '0.5em',
              [`@media (width <= ${layoutSwitchBreakpoint})`]: {
                paddingLeft: 0,
                justifyContent: 'space-between',
              },
            }}>
            <Button
              sx={{
                height: 'fit-content',
              }}
              color='contrast'
              startIcon={<VolunteerActivismIcon />}
              variant='outlined'
              onClick={() => showNominationsDetails(user.email)}>
              See nominations
            </Button>
            {isVote && (
              <Button
                startIcon={<HowToVote />}
                disabled={processing}
                sx={{
                  height: 'fit-content',
                }}
                variant='contained'
                onClick={() => submitVote(user.email)}>
                Vote
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <Dialog onClose={() => setSelectedUser(null)} open={!!selectedUser} fullWidth maxWidth='md'>
        <DialogTitle sx={{ mt: 3 }}>
          <Typography variant='displaySmall'>
            {selectedUser ? `${user?.first_name}'s nominations details` : 'Nominations details'}
          </Typography>
        </DialogTitle>{' '}
        <DialogContent>
          <NomineeDialogContent nominations={selectedUser ? allNominations[selectedUser] : null} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setSelectedUser(null)}
            startIcon={<CloseIcon />}
            variant='contained'
            sx={{ boxShadow: 'none' }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NomineeRow;
