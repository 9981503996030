import { PeriodsResponse } from '../typings';

export const MOCK_PERIOD: PeriodsResponse = {
  nomination_period: {
    start_date: '2017-01-01',
    end_date: '2017-01-01',
    name: 'Nomination',
  },
  voting_period: {
    start_date: '2017-01-01',
    end_date: '2017-01-01',
    name: 'Voting',
  },
};
