import {
  type Theme,
  TextField,
  styled,
  inputBaseClasses,
  formHelperTextClasses,
  textFieldClasses,
  Typography,
} from '@mui/material';
import { CSSObject } from '@mui/material/styles';
import { colorVar, FoldedCorner } from 'components/Layout/FoldedCorner';
import { useController, useFormContext } from 'react-hook-form';

const BorderlessInput = styled(TextField)(({ theme }) => ({
  border: 'none',
  outline: 'none',
  width: '100%',
  height: '100%',
  background: 'transparent',
  resize: 'none',
  minHeight: '70px',
  ...theme.typography.bodyMedium,
  fontFamily: 'Montserrat, sans-serif',
  '&:focus, &:hover, &:focus-visible': {
    border: 'none',
    outline: 'none',
  },
  '&::placeholder': {
    fontWeight: 700,
  },
  '& *': {
    border: '0 !important',
  },
  [`& .${inputBaseClasses.root}, & .${inputBaseClasses.input}`]: {
    height: '100% !important',
    padding: 0,
  },
  [`& .${formHelperTextClasses.root}`]: {
    ...theme.typography.labelSmall,
    marginLeft: 0,
  },
}));

const styles = (theme: Theme): CSSObject => ({
  height: 'calc(100% + 60px)',
  display: 'flex',
  flexDirection: 'column',
  '&, &::before': {
    transition: `${colorVar} ease-out 0.2s`,
  },
  '&:hover, &:focus, &:focus-visible, &:focus-within': {
    '&, &::before': {
      [colorVar]: theme.palette.primary.main,
    },
    color: theme.palette.primary.contrastText,
    [`& .${textFieldClasses.root} *`]: {
      color: `${theme.palette.primary.contrastText} !important`,
    },
  },
  [theme.breakpoints.down('lg')]: {
    height: 260,
  },
});

export const DescriptionInput = () => {
  const { control } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({
    name: 'comment',
    control,
    rules: {
      minLength: 150,
      maxLength: 400,
    },
  });

  return (
    <FoldedCorner color='background.paperVariant' muiStyles={styles}>
      <Typography variant='bodyMedium' fontWeight='bold'>
        Please share WHY #bee represents our values in outstanding way.
      </Typography>
      <BorderlessInput
        {...field}
        error={!!error}
        helperText={
          <>
            Use between 150 and 400 characters for the best nomination <br /> (currently {field.value.length})
          </>
        }
        multiline
        fullWidth
      />
    </FoldedCorner>
  );
};
