export const GET_SETTINGS = 'get_settings';
export const SET_SETTINGS = 'set_settings';

export const GET_PERIOD = 'get_period';
export const GET_CURRENT_PERIOD = 'get_period_current';

export const GET_STATUS = 'get_status';

export const GET_WINNERS = 'get_winners';

export const GET_TOKENS = 'get_tokens';

export const GET_NOMINATIONS = 'get_nominations';
export const NOMINATIONS_SENT = 'nominations_sent';
export const NOMINATIONS_RECEIVED = 'nominations_received';

export const GET_CURRENT_VOTING_STATISTICS = 'get_current_voting_statistics';
export const GET_PREVIOUS_VOTING_STATISTICS = 'get_previous_voting_statistics';
export const GET_USERS = 'get_users';
