import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { useConfirmationModalBasic } from 'providers/confirmationModal/useBasicModal';
import { Box, Typography } from '@mui/material';
import { Highlight } from '../Layout/Highlight';
import { useAddVote } from '../../api/hooks/votes/useAddVote';

const modalContent = {
  title: 'Are you sure?',
  subtitle: (
    <Box display='flex' flexDirection='column' gap={5}>
      <Typography variant='headlineLarge'>
        You can <Highlight text='only vote once!' />
      </Typography>
      <Typography variant='titleLarge'>
        Please look at all nominations and choose who should receive the #BeeOfTheMonth Award
      </Typography>
    </Box>
  ),
};

export const useSnackbarHandlers = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const confirm = useConfirmationModalBasic();

  const displaySuccessSnackbarAndReroute = () => {
    enqueueSnackbar('Your vote has been added', { variant: 'success' });
    navigate('/');
  };

  const displayErrorSnackbar = (error: AxiosError) => {
    if (error.response?.data?.detail) {
      enqueueSnackbar(error.response.data.detail, { variant: 'error' });
    } else {
      enqueueSnackbar('Ups... something went wrong', {
        variant: 'error',
      });
    }
  };

  const { mutate: addVote, isLoading: processing } = useAddVote({
    onSuccess: displaySuccessSnackbarAndReroute,
    onError: displayErrorSnackbar,
  });

  const submitVote = (email: string) => {
    confirm(modalContent).then(() => {
      addVote({ nominee_email: email });
    });
  };

  return {
    submitVote,
    processing,
  };
};
