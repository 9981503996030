import { VoteController } from 'api/controllers/VoteController';
import { useQuery } from 'react-query';
import { PeriodsResponse } from 'api/controllers/typings';
import { BasicQueryOptions } from '../typings';
import { GET_PERIOD } from '../queryKeys';

export const useGetPeriod = ({ onSuccess, onError }: BasicQueryOptions<PeriodsResponse> = {}) => {
  const { data, ...rest } = useQuery({
    queryKey: [GET_PERIOD],
    queryFn: () => VoteController.getPeriods(),
    onSuccess,
    onError,
    retry: false,
  });
  return {
    period: data,
    ...rest,
  };
};
