import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

const MINUTE_IN_MILIS = 1000 * 60;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 3 * MINUTE_IN_MILIS,
    },
  },
});

export const MyQueryClientProvider = React.memo(({ children }: { children?: React.ReactNode }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
));
