import { PeriodType } from 'api/controllers/typings';
import { useGetCurrentPeriod } from 'api/hooks/status';
import { useAuth } from 'providers/auth';
import { Navigate, Outlet } from 'react-router-dom';

export type WithRestriction = {
  isUnresolved: boolean;
  isRestricted: boolean;
  toOnRestricted: string;
};

export const RestrictedRoute = ({ isUnresolved, isRestricted, toOnRestricted }: WithRestriction) => {
  if (isUnresolved) {
    return null;
  }
  if (isRestricted) {
    return <Navigate to={toOnRestricted} />;
  }

  return <Outlet />;
};

export const PrivateRoute = () => {
  const {
    state: { user: currentUser },
  } = useAuth();
  return (
    <RestrictedRoute
      isUnresolved={currentUser === undefined}
      isRestricted={currentUser === null}
      toOnRestricted='/login'
    />
  );
};

export const CurrentPeriodOnly = ({ period }: { period: PeriodType }) => {
  const { currentPeriod } = useGetCurrentPeriod();
  return (
    <RestrictedRoute
      isUnresolved={currentPeriod === undefined}
      isRestricted={currentPeriod?.name !== period}
      toOnRestricted='/'
    />
  );
};
