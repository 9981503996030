import { styled, Container, TypographyProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import { NominationData } from 'api/controllers/typings';
import FormContainer, { FormContainerProps, FormContainerRef } from 'components/Form/FormContainer';
import { useCutCorner } from 'components/Layout/cutCorner';
import { forwardRef } from 'react';
import { percentToHex } from 'utils/numberConversion';
import { DescriptionInput } from './DescriptionInput';
import { PersonSelectWithAvatar } from './PersonSelectWithAvatar';
import { ValueInputAndSubmit } from './ValueAndSubmit';

type NominateFormProps = {
  onSubmit: (data: NominationData) => void;
  isLoading?: boolean;
};

const defaultValues: NominationData = {
  nominee_email: '',
  comment: '',
  value: '',
};

const formStyles = {
  display: 'grid',
  gridTemplateColumns: { md: '1fr', lg: '4fr 4fr 2fr' },
  gap: '40px 60px',
  position: 'relative',
};

const Underline = styled('div')(({ theme }) => {
  return {
    position: 'absolute',
    height: '2px',
    inset: '4em 24px 0',
    margin: 0,
    backgroundColor: theme.palette.neutralVariant[80],
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  };
});

const NominateFormContainer = styled(
  forwardRef<FormContainerRef, FormContainerProps<NominationData>>(({ children, ...props }, ref) => (
    <FormContainer {...props} ref={ref}>
      {children}
    </FormContainer>
  )),
)(({ theme }) => {
  const bgColor = theme.palette.background.paperVariant;
  return {
    background: `linear-gradient(to bottom, ${bgColor} 0%, ${bgColor}${percentToHex(30)} 50%, ${bgColor} 100%)`,
    padding: '60px 0',
  };
});

const stepStyles: Partial<TypographyProps> = {
  sx: {
    mb: '21px',
    color: 'neutralVariant.80',
    display: 'block',
  },
  variant: 'titleLarge',
};

export const NominateForm = forwardRef<FormContainerRef, NominateFormProps>(({ onSubmit, isLoading }, ref) => (
  <NominateFormContainer
    defaultValues={defaultValues}
    formProps={useCutCorner({ corner: ['TL', 'BR'] })}
    onSuccess={onSubmit}
    ref={ref}>
    <Container sx={formStyles}>
      <Underline />
      <div className='nominate-form__step'>
        <Typography {...stepStyles}>1st step</Typography>
        <div className='step_one'>
          <PersonSelectWithAvatar />
        </div>
      </div>
      <div className='nominate-form__step'>
        <Typography {...stepStyles}>2nd step</Typography>
        <div className='step_two'>
          <DescriptionInput />
        </div>
      </div>
      <div className='nominate-form__step'>
        <Typography {...stepStyles}>3rd step</Typography>
        <ValueInputAndSubmit isLoading={isLoading} />
      </div>
    </Container>
  </NominateFormContainer>
));
