import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import Amplify from 'aws-amplify';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AWS_EXPORT } from './aws-export';
import { Client } from './api';
import { API_URL } from './configs';

Client.setupClient(API_URL ?? '');
Amplify.configure(AWS_EXPORT);
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
