import { Auth } from 'aws-amplify';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { API_URL, MOCK_API } from '../../configs';

export interface RequestConfig {
  params?: AxiosRequestConfig['params'];
  headers?: AxiosRequestConfig['headers'];
}

export const client = axios.create({
  baseURL: API_URL,
});

client.interceptors.request.use(
  async request => {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    if (!MOCK_API) {
      if (!token) throw new Error('Missing id token');
    }

    if (token && request.headers) {
      request.headers.Authorization = token;
    }

    return request;
  },
  error => Promise.reject(error),
);

client.interceptors.response.use(
  response => {
    if (response.status === 202) {
      return client.request(response.config);
    }

    return response;
  },
  async err => {
    const error: AxiosError = { ...err };
    const originalRequest = error.config;

    if (error.response?.status === 401) {
      await Auth.currentSession();
      return axios(originalRequest);
    }

    // return Error object with Promise
    return Promise.reject(err);
  },
);
