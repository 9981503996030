import { type CSSObject, styled, Theme } from '@mui/material/styles';
import { HTMLAttributes } from 'react';
import { extractProperty } from 'utils/extractProperty';
import { useCutCorner } from './cutCorner';

export type FoldedCornerProps = {
  color?: string;
  muiStyles?: CSSObject | ((theme: Theme) => CSSObject);
} & HTMLAttributes<HTMLDivElement>;

export const colorVar = '--background-color';

// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace CSS {
  interface PropertyDefinition {
    name: string;
    syntax?: string;
    inherits: boolean;
    initialValue?: string;
  }
  function registerProperty(propertyDefinition: PropertyDefinition): undefined;
}

CSS.registerProperty?.({
  name: colorVar,
  syntax: '<color>',
  inherits: false,
  initialValue: '#fff',
}); // provides smooth animation for bg color if supported, see https://developer.mozilla.org/en-US/docs/Web/CSS/@property

export const FoldedCorner = styled(
  ({ children, className, style, ...props }: FoldedCornerProps) => (
    <div {...props} {...useCutCorner({ corner: 'TR', cornerSize: '40px', className, style })}>
      {children}
    </div>
  ),
  {
    shouldForwardProp: propName => propName !== 'color' && propName !== 'muiStyles',
  },
)(({ color, theme, muiStyles }) => ({
  [colorVar]: (extractProperty(theme.palette, color) as string | undefined) ?? color,
  padding: '30px',
  paddingTop: '40px',
  position: 'relative',
  ...theme.palette.blends(1, `var(${colorVar})`),
  ...(typeof muiStyles === 'function' ? muiStyles(theme) : muiStyles),
  '&:before': {
    [colorVar]: (extractProperty(theme.palette, color) as string | undefined) ?? color,
    content: '""',
    width: '40px',
    height: '40px',
    position: 'absolute',
    top: 0,
    right: 0,
    ...theme.palette.blends(5, `var(${colorVar})`),
  },
}));
