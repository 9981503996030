import { FC } from 'react';

import { Typography, Box } from '@mui/material';
import { Highlight } from './Highlight';

interface PageTitleProps {
  description: string;
}

export const PageTitle: FC<PageTitleProps> = ({ description }) => {
  const title = (
    <Typography variant='displayMedium'>
      <Highlight text='Welcome' /> to our Bee of The Month platform!
    </Typography>
  );
  return (
    <Box>
      <Box display='flex'>{title}</Box>
      <Box mt='1.875em'>
        <Typography variant='bodyLarge'>{description}</Typography>
      </Box>
    </Box>
  );
};
