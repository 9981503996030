import { useQuery } from 'react-query';
import { SettingsController } from 'api/controllers/SettingsController';
import { MySettingsResponse } from 'api/controllers/typings';
import { BasicQueryOptions } from '../typings';
import { GET_SETTINGS } from '../queryKeys';

export const useGetSettings = ({ onSuccess, onError }: BasicQueryOptions<MySettingsResponse> = {}) => {
  const { data, ...rest } = useQuery({
    queryKey: [GET_SETTINGS],
    queryFn: () => SettingsController.getSettings(),
    onSuccess,
    onError,
    retry: false,
  });
  return {
    settings: data,
    ...rest,
  };
};
