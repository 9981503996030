import { createContext } from 'react';

import { AuthContextType } from './typings';

const AuthContext = createContext<AuthContextType>({
  state: {
    isAuth: false,
    user: undefined,
  },
  actions: {
    /* eslint-disable @typescript-eslint/no-empty-function */
    signIn: () => {},
    signOut: () => {},
    /* eslint-enable @typescript-eslint/no-empty-function */
  },
});

export default AuthContext;
