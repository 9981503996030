/* eslint-disable no-console */
/* eslint-disable react/jsx-no-constructed-context-values */
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { useExistingContext } from 'providers/useExistingContext';
import { useEffect, useState } from 'react';

import { MOCK_API } from '../../configs/environment';
import AuthContext from './context';
import { AuthContextType, UserData } from './typings';

type AuthProviderProps = {
  children?: React.ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [user, setUser] = useState<UserData | null | undefined>(undefined);

  async function getUserData(): Promise<UserData | null> {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      setIsAuth(true);
      return currentUser.attributes;
    } catch (error) {
      setIsAuth(MOCK_API);
      return null;
    }
  }

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
        case 'signIn':
          getUserData().then(setUser);
          break;
        case 'signOut':
          setUser(null);
          setIsAuth(false);
          break;
        default:
          break;
      }
    });

    getUserData().then(setUser);

    return unsubscribe;
  }, []);

  const signIn = (callback?: (isSuccess: boolean) => void): void => {
    Auth.federatedSignIn({ customProvider: 'Google' }).then(() => {
      if (callback) {
        callback(true);
      }
    });
  };

  const signOut = (): void => {
    Auth.signOut();
  };

  const value: AuthContextType = {
    state: { isAuth, user },
    actions: { signIn, signOut },
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useExistingContext(AuthContext);
