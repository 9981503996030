import { generatePaginationQueryParams } from 'api/helpers/paginationQuery';
import { Client } from '../client';
import { PaginationOptions, PaginatedWinnersResponse, WinnerResponse } from './typings';

class WinnerController {
  static client = Client;

  static getWinner(id: string): Promise<WinnerResponse> {
    return this.client.get(`/winners/${id}`);
  }

  static getPaginatedWinners({ exclusiveStartKey, limit = 10 }: PaginationOptions): Promise<PaginatedWinnersResponse> {
    return this.client.get(`/winners?${generatePaginationQueryParams({ exclusiveStartKey, limit })}`);
  }
}

export { WinnerController };
