import { FC } from 'react';
import { Box, Stack, Typography, styled } from '@mui/material';
import { FoldedCorner } from 'components/Layout/FoldedCorner';
import { PersistantTooltip } from 'components/PersistantTooltip/TopdownTooltip';
import { NominationItem } from 'api/controllers/typings';

interface NomineeDialogContentProps {
  nominations: NominationItem[] | null;
}

const NominationContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: '88px',
  marginBottom: '2em',
});

export const NomineeDialogContent: FC<NomineeDialogContentProps> = ({ nominations }) => {
  return (
    <Stack>
      {nominations?.map(item => (
        <NominationContainer>
          <Box position='relative' width='100%'>
            <FoldedCorner
              color='background.paperVariant'
              style={{
                zIndex: 2,
                overflowWrap: 'break-word',
                wordWrap: 'break-word',
                whiteSpace: 'normal',
              }}>
              <Typography variant='bodyMedium' style={{ textAlign: 'left' }}>
                {item.comment}
              </Typography>
            </FoldedCorner>
            <Box
              sx={{
                position: 'absolute',
                bottom: '-0.7em',
                left: '2em',
                zIndex: 3,
              }}>
              <PersistantTooltip variant='titleSmall' position='relative'>
                {item.value}
              </PersistantTooltip>
            </Box>
          </Box>
        </NominationContainer>
      ))}
    </Stack>
  );
};
