import { VoteController } from 'api/controllers/VoteController';
import { useQuery } from 'react-query';
import { Period } from 'api/controllers/typings';
import { BasicQueryOptions } from '../typings';
import { GET_CURRENT_PERIOD } from '../queryKeys';

export const useGetCurrentPeriod = ({ onSuccess, onError }: BasicQueryOptions<Period> = {}) => {
  const { data, ...rest } = useQuery({
    queryKey: [GET_CURRENT_PERIOD],
    queryFn: () => VoteController.getCurrentPeriod(),
    onSuccess,
    onError,
  });
  return {
    currentPeriod: data,
    ...rest,
  };
};
