import { Button, styled, ButtonProps, buttonClasses } from '@mui/material';
import GradientIcon from '@mui/icons-material/Gradient';
import { forwardRef, HTMLAttributes } from 'react';

export const NFTButton = styled(
  (props: ButtonProps) => (
    <Button {...props} startIcon={<GradientIcon />} color='contrast'>
      NFT
    </Button>
  ),
  {
    shouldForwardProp: propName => !['children', 'startIcon'].includes(propName as string),
  },
)(({ theme: { typography } }) => ({
  ...typography.labelSmall,
  padding: '3px 4px',
}));

export const TokenTile = styled(
  forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement> & { img?: string }>(({ children, ...props }, ref) => (
    <div ref={ref} {...props}>
      {children}
    </div>
  )),
  {
    shouldForwardProp: prop => prop !== 'img',
  },
)(({ img, theme: { spacing, palette } }) => ({
  backgroundImage: `url(${img})`,
  display: 'flex',
  width: '264px',
  height: '264px',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  padding: spacing(1),
  position: 'relative',
  isolation: 'isolate',
  '&:before': {
    zIndex: -1,
    content: '""',
    position: 'absolute',
    background: `linear-gradient(0deg, ${palette.neutralVariant[95]} 0%, ${palette.neutralVariant[95]}00 100%)`,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
}));

export const PersonBox = styled(
  forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(({ children, ...props }, ref) => (
    <div ref={ref} {...props}>
      {children}
    </div>
  )),
)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1em',
  color: theme.palette.neutral[0],
  inlineSize: 'min-content',
  position: 'relative',
}));

export const HorizontalContainer = styled('div')(({ theme: { spacing } }) => ({
  width: '150px',
  [`& > .${buttonClasses.root}`]: {
    marginTop: spacing(1.5),
  },
}));
