import React, { forwardRef, useImperativeHandle, useRef, ReactNode, useMemo } from 'react';
import SlickSlider, { type Settings } from 'react-slick';

type SliderProps = {
  children: ReactNode;
  className?: string;
} & Partial<Settings>;

export type SliderRef = {
  next: () => void;
  prev: () => void;
};

const initSettings: Settings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 3000,
  centerMode: true,
  variableWidth: true,
};

const Slider = forwardRef<SliderRef, SliderProps>(({ children, className, ...settings }, ref) => {
  const sliderRef = useRef<SlickSlider>(null);
  const mySettings = useMemo(
    () => ({
      ...initSettings,
      className,
      ...settings,
    }),
    [settings, className],
  );

  useImperativeHandle(ref, () => ({
    next: () => {
      sliderRef?.current?.slickNext();
    },
    prev: () => {
      sliderRef?.current?.slickPrev();
    },
  }));

  return (
    <SlickSlider {...mySettings} ref={sliderRef}>
      {children}
    </SlickSlider>
  );
});

export default React.memo(Slider);
